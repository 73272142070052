import React,{Component} from 'react';
import {Switch, Route} from 'react-router-dom';
import { connect } from 'react-redux';
import { setUserData } from './redux/actions/ActionsUserData';
import MyStorage from './helpers/MyStorage';

import Login from '../screens/Login';
import Home from '../screens/Home';
//users
import Users from '../screens/Users/Index';
import Clientes from '../screens/Users/Clientes';
import User from '../screens/Users/User';
import Profile from '../screens/Users/Profile';
//settings
import Provincias from '../screens/Settings/Provincias';
import Parroquias from '../screens/Settings/Parroquias';
import Cantones from '../screens/Settings/Cantones';
import Tarifas from '../screens/Tarifas';
import Nodos from '../screens/Nodos';
import Soporte from '../screens/Soporte';
import Factura from '../screens/Factura';
import Cobros from '../screens/Cobros';
import Arqueo from '../screens/Cobros/Arqueo';
import Contratos from '../screens/Contrato';
//reportes
import RelacionCliente from '../screens/Reportes/RelacionCliente';
import Averias from '../screens/Reportes/Averias';
import ReclamosCapacidad from '../screens/Reportes/ReclamosCapacidad';
import ReporteUsuarios from '../screens/Reportes/ReporteUsuarios';
import ReporteTarifas from '../screens/Reportes/ReporteTarifas';

/*const Routes = () =>{
  return (
    <Switch >
      <Route exact path= '/' component={Login}/>
    </Switch >
  )
}*/

class Routes extends Component {

    /*constructor(props) {
        super(props);
    }*/
    componentDidMount() {
        this.checkLogin();
    }

    render(){

      if (this.props.user && this.props.user.user) {
        return (
          <Switch >
            <Route exact path= '/' component={Home}/>
            <Route path= '/users' component={Users}/>
            <Route path= '/clientes' component={Clientes}/>
            <Route path= '/user' component={User}/>
            <Route path= '/perfil' component={Profile}/>
            <Route path= '/provincias' component={Provincias}/>
            <Route path= '/cantones' component={Cantones}/>
            <Route path= '/parroquias' component={Parroquias}/>
            <Route path= '/nodos' component={Nodos}/>
            <Route path= '/tarifas' component={Tarifas}/>
            <Route path= '/soporte' component={Soporte}/>
            <Route path= '/facturas' component={Factura}/>
            <Route path= '/cobros' component={Cobros}/>
            <Route path= '/contratos' component={Contratos}/>
            <Route path= '/relacioncliente' component={RelacionCliente}/>
            <Route path= '/averias' component={Averias}/>
            <Route path= '/reclamocapacidad' component={ReclamosCapacidad}/>
            <Route path= '/reporte_tarifa' component={ReporteTarifas}/>
            <Route path= '/reporte_usuario' component={ReporteTarifas}/>
            <Route path= '/arqueo' component={Arqueo}/>
          </Switch >
        )
        //<Route path= '/perfil/:id' component={Profile}/> with params
        //https://riptutorial.com/es/react-router/example/30227/enrutamiento-con-parametros-escritos-
      } else {
        //not loggin
        return (
          <Switch >
            <Route exact path= '/' component={Login}/>
          </Switch >
        )
      }


    }


    checkLogin = () => {
        new MyStorage().getUserInfo().then(user => {
            //console.log("usuario: "+user);
            if (user) {
                user = JSON.parse(user);
                this.props.setUserData(user);
            } else {
            }
           /* this.setState({
                show_init_load: false
            })*/
        });
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.user_data,
    }
}

const actions = {
    setUserData,
}


export default connect(mapStateToProps, actions)(Routes);
