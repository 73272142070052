export const IVA= 12;
export const YEAR_COPILATION= 2020;

//TIPO DE TARIFA o tipo de cliente
export const tipos = [
   {"id":"R", "nombre":  "Residencial"},
   {"id":"CI", "nombre":"CiberCafe"},
   {"id":"CO", "nombre": "Corporativo"}
];

export const tipo_conexion = [
   {"id":"C", "nombre":  "Conmutada"},
   {"id":"N", "nombre":"No Conmutada"}
];
//Cobre, Cable, Coaxial, Fibra Óptica, Medio Inalamb…
export const tipo_enlace= [
  {"id":"C0", "nombre":  "Cobre"},
  {"id":"CA", "nombre":  "Cable"},
  {"id":"COA", "nombre":  "Coaxial"},
  {"id":"F", "nombre":  "Fibra Óptica"},
  {"id":"MI", "nombre":  "Media Inalámbrico"},
]

//Canal de comunicación o reclamo
//PERSONALIZADO, TELEFÓNICO, CORREO ELECTRÓNICO, OFICIO, PÁGINA WEB
export const canal = [
   {"id":"P", "nombre":  "Personalizado"},
   {"id":"T", "nombre": "Telefónico"},
   {"id":"E", "nombre": "Correo Electrónico"},
   {"id":"O", "nombre": "Oficio"},
   {"id":"W", "nombre": "Página Web"}
];

//Origen de la respuesta de la conversacion
export const origen_respuesta = [
   {"id":"S", "nombre":  "Soporte"},
   {"id":"A", "nombre":"Autor"}
];

export const getValueByKey = (id, items)=>{
  let result="";
      items.filter((data)=>{
        if(data.id === id){
          result+= data.nombre;
        }
      })
    return result;
}

export const meses= ["Seleccione ","Enero","Febrero",
              "Marzo", "Abril","Mayo", "Junio",
              "Julio", "Agosto","Septiembre",
              "Octubre", "Noviembre","Diciembre"
            ];
