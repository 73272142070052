import React,{Component} from 'react';
import WebApi from '../../components/helpers/WebApi';
import {IVA} from '../../components/helpers/Constantes';
import {CustomInput, ComboBox} from '../../components/common/Parts';
import Spinner from '../../components/common/Spinner';
// ES6 Modules or TypeScript
import Swal from 'sweetalert2';
import NumberFormat from 'react-number-format';
import { ReactSearchAutocomplete } from "react-search-autocomplete";
// CommonJS
//const Swal = require('sweetalert2');
import { connect } from 'react-redux';

class Factura extends Component {
  state={
    id:"",
    nombre:"",
    mensaje:"",
    show_loader:false,
    tarifas:[],
    tarifa:0,
    nombre_tarifa:"",
    precio_tarifa: 0,
    detalle:[],
  }

  ITEM=this.props.item || false;
  componentDidMount(){
    this.getFactura();
  }

  render(){
    return(
      <form action="#!" onSubmit={this.handleSubmmit}>
        {this.state.mensaje!=="" && <div className="alert alert-info">{this.state.mensaje}</div>}
        <div className="row">
          <Spinner visible={this.state.show_loader}/>
          <div className="col-sm-12">
              <div className="form-group">
                <label forhtml="cliente">Cliente: </label>
                {this.ITEM ?
                  <span><br/>{this.ITEM.cliente}</span>
                  :
                  <ReactSearchAutocomplete
                    items={this.state.clientes}
                    onSearch={this.handleOnSearch}
                    onSelect={this.handleOnSelect}
                    autoFocus
                  />
                }
              </div>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-12 template-demo">
            <h4>Descripción</h4>
            <div class="form-group row">
              <div class="col-sm-9">
                <ReactSearchAutocomplete
                  items={this.state.tarifas}
                  onSearch={this.handleOnSearchTarifa}
                  onSelect={this.handleOnSelectTarifa}
                  placeholder={this.state.nombre_tarifa ||  "Añadir tarifa"}
                  autoFocus
                />
              </div>
              <label className="col-sm-3 col-form-label">
                <button type="button" className="btn btn-warning" onClick={()=>this.anadirItem()}> Añadir  </button>
              </label>
            </div>
            <table class="table table-striped" style={{marginBottom: 40}}>
                <thead>
                  <tr>
                    <th>Cant</th>
                    <th>Tarifa</th>
                    <th>Unit</th>
                    <th>Total</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                {
                    this.state.detalle.map((item, index)=>
                    <tr key={index}>
                      <td className="py-1">{item.cant}</td>
                      <td>{item.nombre}</td>
                      <td>$ {item.valor}</td>
                      <td>$ {item.valor*item.cant}</td>
                      <td onClick={()=>{ this.setState({detalle: this.arrayFiltered(index) }) }}>x</td>
                    </tr>
                    )
                }
                </tbody>
            </table>
          </div>
        </div>
        <div className="row">
            <div className="col-sm-8">
            </div>
            <div class="col-xl-4 grid-margin">
              <div className="card stretch-card mb-3">
                <div className="card-body d-flex flex-wrap justify-content-between">
                  <div>
                    <h4 className="font-weight-semibold mb-1 text-black"> SubTotal </h4>
                  </div>
                  <h3 className="text-success font-weight-bold">
                    <NumberFormat decimalScale={2} fixedDecimalScale={true} prefix={'$'} displayType={'text'} value={this.getSubTotal()} />
                  </h3>
                </div>
                <div className="card-body d-flex flex-wrap justify-content-between">
                  <div>
                    <h4 className="font-weight-semibold mb-1 text-black"> IVA </h4>
                  </div>
                  <h3 className="text-success font-weight-bold">
                    <NumberFormat decimalScale={2} fixedDecimalScale={true} prefix={'$'} displayType={'text'} value={this.getSubTotal()*IVA/100} />
                  </h3>
                </div>
                <div className="card-body d-flex flex-wrap justify-content-between">
                  <div>
                    <h4 className="font-weight-semibold mb-1 text-black"> Total </h4>
                  </div>
                  <h3 className="text-success font-weight-bold">
                    <NumberFormat decimalScale={2} fixedDecimalScale={true} prefix={'$'} displayType={'text'} value={this.getSubTotal()+(this.getSubTotal()*IVA/100)} />
                  </h3>
                </div>
              </div>
            </div>
        </div>
        <div className="row">
          <div className="col-sm-12">
            <button type="submit" className="btn btn-primary mr-2"> Guardar </button>
            {this.props.hideModal && <button className="btn btn-light mr-2" onClick={this.props.hideModal} type="button" >Cancelar</button>}
            {this.ITEM &&
              <button className="btn btn-danger" type="button" onClick={this.alertConfirm} >Eliminar</button>}
            {!this.ITEM && <button className="btn btn-light mr-2" type="button" onClick={this.resetForm}>Limpiar </button>}
          </div>
        </div>
      </form>
    );
  }

  arrayFiltered = (index) => {
    if(index==0){
      return [];
    }
    let data= this.state.detalle.filter(function(ele){
      if(ele.id != index ){
        return ele;
      }
    });
    console.log("datas: ", data);
    if(data.lenght === 0){
      return [];
    }  else {
      return data;
    }
  }

  handleChange = (e) => {
    let name=e.target.name;
    this.setState({
        [name]: e.target.value
    });
  }

  anadirItem = ()=> {
    let {detalle}= this.state;
    detalle.push({
      id: detalle.length+1,
      cant: 1,
      nombre: this.state.nombre_tarifa,
      tarifa:this.state.tarifa,
      valor: this.state.precio_tarifa
    });
    this.setState({detalle});
  }


  handleSubmmit = (e) => {
    e.preventDefault();
    let { cliente,detalle} =this.state;
    let jsonDetail = JSON.stringify(detalle);

    if(cliente.trim() < 2 ) {
      alert("Debe seleccionar el cliente");
      return;
    }

    if(detalle.length===0){
      alert("Debe añadir al menos un item al detalle");
      return;
    }

    this.setState({show_loader:true});
      new WebApi().setFactura( this.ITEM.id, cliente, jsonDetail).then(result => {
        setTimeout(() => {
            this.processsSetFactura(result);
        }, 300)
    })
  }

  processsSetFactura = (result) => {
    if (result.no_result) {
        return
    }
    this.setState({show_loader:false});
    let { data } = result;
    if (data.result) {
      this.setState({
        id: data.id,
        mensaje: data.message,
      });
      typeof this.props.handleGetFacturas !== "undefined" && this.props.handleGetFacturas()
      typeof this.props.hideModal !== "undefined" && this.props.hideModal()
    } else {
        alert(data.message);
    }
  }

  getFactura = () => {
    if(!this.ITEM){
      return;
    }
    this.setState({
      id: this.ITEM.id,
      nombre:this.ITEM.nombre,
      provincia: this.ITEM.provincia,
      canton: this.ITEM.canton,
      parroquia: this.ITEM.parroquia,
      direccion: this.ITEM.direccion,
      latitud: this.ITEM.latitud,
      longitud: this.ITEM.longitud,
    })
  }



  alertConfirm = ()=> {
    Swal.fire({
        title: 'Confirma',
        text: '¿Estás seguro que deseas eliminarlo?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Si',
        cancelButtonText: 'No, cancelar'
      }).then((result) => {
        if (result.value) {
          this.deleteCanton();
          /*
          Swal.fire(
            'Deleted!',
            'Your imaginary file has been deleted.',
            'success'
          )
        */
        // For more information about handling dismissals please visit
        // https://sweetalert2.github.io/#handling-dismissals
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          console.log("cancelar");
        }
      })
  }



  resetForm = () => {
    this.setState({
      id: 0,
      nombre: "",
      provincia: 0,
      mensaje: "",
    })
  }

  //Empieza la parte de las funciones para ReactSearchAutocomplete

    handleOnSearch = (string, cached) => {
      // onSearch returns the string searched and if
      // the values are cached. If the values are cached
      // "cached" contains the cached values, if not, returns false
      console.log(string, cached);
      this.setState({show_loader:true});
        new WebApi().getSearch( string , 1).then(result => {
          this.processsSearch(result);
      });
    }

    processsSearch = (result) => {
      this.setState({show_loader:false});
      if (result.no_result) {
          return
      }
      let { data } = result;
      if (data.result) {
        this.setState({clientes: data.usuarios})
      }
      console.log(data.clientes);
    }

    handleOnSelect = item => {
      // the item selected
      this.setState({
        cliente:item.id,
      });
      console.log(item);
  }

  handleOnSearchTarifa = (string, cached) => {
    console.log(string, cached);
    this.setState({show_loader:true});
      new WebApi().getSearchTarifas( string , 1).then(result => {
        this.processSearchTarifa(result);
    });
  }

  processSearchTarifa = (result) => {
    this.setState({show_loader:false});
    if (result.no_result) {
        return
    }
    let { data } = result;
    if (data.result) {
      this.setState({tarifas: data.tarifas})
    }
    //console.log(data.clientes);
  }

  handleOnSelectTarifa = item => {
    // the item selected
    this.setState({
      tarifa:item.id,
      nombre_tarifa: item.name,
      precio_tarifa: item.precio,
    });
    //console.log(item);
  }

  getSubTotal = () => {
    let total=0;
    this.state.detalle.forEach((item, i) => {
      total+=parseFloat(item.valor*item.cant);
    });
    return total;
  }
}

const mapStateToProps = (state) => {
    return {
        user: state.user_data
    }
}
const actions = {};
export default connect(mapStateToProps, actions)(Factura);
