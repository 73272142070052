import React,{Component} from 'react';
import WebApi from '../../components/helpers/WebApi';
import {IVA} from '../../components/helpers/Constantes';
import { Footer, NewItem} from '../../components/common/Parts';
import RightNavigation from '../../components/common/RightNavigation';
import TopNavigation from '../../components/common/TopNavigation';
import Spinner from '../../components/common/Spinner';
//import EscogerParroquia from '../../components/common/EscogerParroquia';
import Paginate from '../../components/common/Pagination';
import Cobro from './Cobro';
import '../../App.css';
//https://www.npmjs.com/package/react-moment
import moment from 'moment/min/moment-with-locales';
import Moment from 'react-moment';

//import 'moment-timezone';
Moment.globalMoment = moment;
Moment.globalLocale = 'es';
Moment.globalFormat = 'dddd DD-MMM-YYYY HH:mm';

class Cobros extends Component {
  PAGE_NUM=1;
  state ={
      search : '',
      cobros: [],
      total: 0,
      perPage: 0,
      item_selected: false,
      showCobroEdit:false,
      show_loader: false,
      parroquia:0,
  }

  componentDidMount(){
    this.handleGetCobros();

  }

  handleChange = (e) => {
      this.setState({
          [e.target.id]: e.target.value
      })
  }

  handleSubmit = (e) => {
      e.preventDefault()
      this.handleGetCobros();
  }

  handleGetCobros = () => {
      console.log(this.state);
      this.setState({show_loader:true, cobros: []});
      new WebApi().getCobros(this.state.search, this.PAGE_NUM).then(result => {
        this.processGetCobrosResponse(result);
      });
  }

  processGetCobrosResponse = (result) => {
      this.setState({show_loader:false});
      if (result.no_result) {
          return
      }
      let { data } = result;
      console.log("datas ", data);
      if (data.result) {
          this.setState({total: data.total, cobros: data.cobros, perPage: data.perPage});
      } else {
          alert(data.message);
      }
  }

  onSelectParroquia = (item) => {
    this.setState({parroquia: item.id},
      ()=>this.handleGetCobros()
    )
  }

  buscador= ()=> {

    return(
      <form className="d-flex align-items-center" onSubmit={this.handleSubmit}>
        <div className="input-group">
          <div className="input-group-prepend">
            <i className="input-group-text border-0 mdi mdi-magnify"></i>
          </div>
          <input type="text" className="form-control border-0" id="search" name="search" placeholder="Buscar" onChange={this.handleChange}/>
          <div className="input-group-append">
            <button className="btn btn-sm btn-primary" type="submit"> Ir </button>
          </div>
        </div>

      </form>
    )
  }

  table = () => {

      return (
        <React.Fragment>
          <Spinner visible={this.state.show_loader}/>
            <div className="row">
            { this.state.cobros.map((item, index)=>
              <div className="col-sm-4 stretch-card grid-margin" key={item.usuario_Id} onClick={()=>this.setState({item_selected:item, showCobroEdit: true})}>
                <div className="card">
                  <div className="card-body px-3 text-dark">
                    <div className="d-flex justify-content-between">
                      <p className="text-muted font-13 mb-0">Usuario # {item.usuario_Id}</p>
                      <i className="mdi mdi-coin"></i>
                    </div>
                    <h5 className="font-weight-semibold"> {item.nombre} {item.apellido} </h5>
                    <div className="d-flex justify-content-between font-weight-semibold">
                      <p className="mb-0">
                        <i className="mdi mdi-star star-color pr-1"></i>Saldo ${(this.calculateIva(item.total)-this.calculateIva(item.valor)).toFixed(2)} </p>
                      <p className="mb-0">Total facturado: ${this.calculateIva(item.total)}</p>
                    </div>
                  </div>
                </div>
              </div>
              )
            }
            </div>
        </React.Fragment>
      )
    }

  calculateIva = (valor) => {
    let iva_calc= 1+(IVA/100);
    return parseFloat(valor*iva_calc).toFixed(2);
  }

  hideModal = () => {
    this.setState({
      showCobroEdit:false,
    })
  }

  render (){
    let adjacents  = 4; //brecha entre páginas después de varios adyacentes
    //let offset = (this.PAGE_NUM - 1) * this.state.perPage;
    let total_pages = Math.ceil(this.state.total/this.state.perPage);

    return (
      <div className="container-scroller">
        {/*<!-- partial:partials/_sidebar.html -->*/}
        <RightNavigation/>
        {/*<!-- partial -->*/}
        <div className="container-fluid page-body-wrapper">
          {/*<!-- partial:partials/_settings-panel.html -->*/}
          {/*<!-- partial -->*/}
          {/*<!-- partial:partials/_navbar.html -->*/}
          <TopNavigation/>

          {/*<!-- partial -->*/}
          <div className="main-panel">
            <div className="content-wrapper pb-0">
            
              {/*Content here*/}
              {this.state.showCobroEdit &&
                <div className="modal-dialog modal-lg" role="dialog" aria-hidden="false">
                  <div className="modal-content">
                  <div className="modal-header">
                    <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={this.hideModal}>
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  <div className="modal-body" style={{backgroundColor:"#fff"}}>
                    <Cobro item={this.state.item_selected}  hideModal={this.hideModal} handleGetCobros={this.handleGetCobros}/>
                  </div>
                </div>
              </div>
              }
              {!this.state.showCobroEdit && this.buscador()}

              {!this.state.showCobroEdit && this.table()}
              {!this.state.showCobroEdit &&
                <Paginate tpages={total_pages}  page={this.PAGE_NUM} adjacents={adjacents} load_next={this.load_next}/> }
              {this.state.cobros.length > 0 &&
                <div style={{ textAlign:"right",marginTop: 30 }}>Total de cobros: {this.state.total}</div>
              }
            </div>
            {/*<!-- content-wrapper ends -->*/}
            {/*<!-- partial:partials/_footer.html -->*/}
            <Footer/>
            {/*<!-- partial -->*/}
          </div>
          {/*<!-- main-panel ends -->*/}
        </div>
        {/*<!--page-body-wrapper ends-->*/}

      </div>
    )
  }

  refresh = () => {
    this.handleGetCobros();
  }

  load_next = (page) => {
    this.PAGE_NUM= page;
    this.handleGetCobros();
  }



}



export default Cobros;
