import React,{Component} from 'react';
import WebApi from '../../components/helpers/WebApi';
import {canal} from '../../components/helpers/Constantes';
import {CustomInput, ComboBox} from '../../components/common/Parts';
import CustomTextArea from '../../components/common/CustomTextArea';
import Spinner from '../../components/common/Spinner';
import { ReactSearchAutocomplete } from "react-search-autocomplete";
import Preview from './Preview';
// ES6 Modules or TypeScript
import Swal from 'sweetalert2';
// CommonJS
//const Swal = require('sweetalert2');
import { connect } from 'react-redux';

class Ticket extends Component {
  state={
    id:"",
    comentario:"",
    usuario: 0,
    usuarios:[],
    archivo: "",
    contrato: "",
    contratos: [],
    file_object: {},
    tipos_de_reclamo: [],
    tipo_de_reclamo:0,
    canal:"",
    parroquia:0,
    mensaje:"",
    provincia:0,
    canton:0,
    provincias:[],
    cantones: [],
    parroquias: [],
    show_loader:false,
    show_edit: false,
  }

  ITEM=this.props.item || false;

  componentDidMount(){
    this.getTicket();
    this.handleProvincias();
    this.handleTipodeReclamo();
  }

  handleOnSearch = (string, cached) => {
    // onSearch returns the string searched and if
    // the values are cached. If the values are cached
    // "cached" contains the cached values, if not, returns false
    console.log(string, cached);
    this.setState({show_loader:true});
      new WebApi().getSearch( string , 1).then(result => {
        this.processsSearch(result);
    });
  }

  processsSearch = (result) => {
    this.setState({show_loader:false});
    if (result.no_result) {
        return
    }
    let { data } = result;
    if (data.result) {
      this.setState({usuarios: data.usuarios})
    }
    console.log(data.usuarios);
  }

  handleOnSelect = item => {
    // the item selected
    this.setState({
      usuario:item.id
    }, ()=> //search contratos
          this.handleContratos()
    );
    console.log(item);

  }

  handleOnFocus = () => {
    console.log("Focused");
  }

  render(){
    return(
      <React.Fragment>
        { this.ITEM &&
          <div className="row">
            <div className="col-sm-12">
              <button type="button" style={{float:"right"}} className="btn btn-primary" onClick={()=>this.setState(prevState=>({show_edit: !prevState.show_edit}))}>
               { this.state.show_edit?  "Detalles del ticket" : "Editar Ticket" }
              </button>
            </div>
          </div>
        }
        {this.state.show_edit && this.edit()}
        {!this.state.show_edit &&
          <Preview item={this.ITEM} handleGetTickets={this.props.handleGetTickets} hideModal={this.props.hideModal}/>}
      </React.Fragment>
    )
  }

  //<button className="btn btn-danger" type="button" onClick={this.alertConfirm} >Eliminar</button>
  //{!this.ITEM && <button className="btn btn-light mr-2" type="button" onClick={this.resetForm}>Limpiar </button>}

  edit = () => {
    return(
      <form action="#!" onSubmit={this.handleSubmmit}>
        {this.state.mensaje!=="" && <div className="alert alert-info">{this.state.mensaje}</div>}
        <div className="row">
          <Spinner visible={this.state.show_loader}/>
          <div className="col-sm-6">
            <div className="form-group">
              <label forhtml="usuario">Cliente que realiza el reclamo: </label>
              {this.ITEM ?
                <span><br/>{this.ITEM.nombre_usuario}</span>
                :
                <ReactSearchAutocomplete
                  items={this.state.usuarios}
                  onSearch={this.handleOnSearch}
                  onSelect={this.handleOnSelect}
                  onFocus={this.handleOnFocus}
                  autoFocus
                />
              }
            </div>
            <ComboBox
              items={this.state.contratos}
              name={"contrato"}
              label={"Selecciona un contrato"}
              onChangeText={this.handleChange}
              value={this.state.contrato}
              required
            />
            <CustomInput
              type="file"
              name={"archivo"}
              label={this.ITEM ? this.state.archivo_subido!==""? "Reemplaza el archivo subido": "Añade un adjunto"   : "Sube un adjunto" }
              onChangeText={this.handleChange}
              value={this.state.archivo}
            />
            {this.ITEM && <a href={new WebApi()._URL_APP+this.ITEM.archivo}  rel="noopener noreferrer"  target="_blank">Descargar archivo subido</a>}
            <br/><br/>
            <ComboBox
              items={this.state.tipos_de_reclamo}
              name={"tipo_de_reclamo"}
              label={"Tipo de reclamo"}
              onChangeText={this.handleChange}
              value={this.state.tipo_de_reclamo}
              required
            />
            <CustomTextArea
              name={"comentarios"}
              label={"Descripción del reclamo"}
              onChangeText={this.handleChange}
              value={this.state.comentarios}
              required
            />
          </div>
          <div className="col-sm-6">
            <ComboBox
              items={canal}
              name={"canal"}
              label={"Canal"}
              onChangeText={this.handleChange}
              value={this.state.canal}
              required
            />
            <ComboBox
              items={this.state.provincias}
              name={"provincia"}
              label={"Provincia"}
              onChangeText={this.handleChange}
              value={this.state.provincia}
              required
            />
            <ComboBox
              items={this.state.cantones}
              name={"canton"}
              label={"Canton"}
              onChangeText={this.handleChange}
              value={this.state.canton}
              required
            />
            <ComboBox
              items={this.state.parroquias}
              name={"parroquia"}
              label={"Parroquia"}
              onChangeText={this.handleChange}
              value={this.state.parroquia}
              required
            />

            <button type="submit" className="btn btn-primary mr-2"> Guardar </button>
            {!this.ITEM && <button className="btn btn-light mr-2" type="button" onClick={this.resetForm}>Limpiar </button>}
            {this.props.hideModal && <button className="btn btn-light mr-2" onClick={this.props.hideModal} type="button" >Cancelar</button>}
          </div>
        </div>
      </form>
    );
  }

  handleChange = (e) => {
    let name=e.target.name;
    this.setState({
        [name]: e.target.value
    }, ()=>this.subCargaDeDatos(name));

    if(name==="archivo"){
      this.setState({
        "file_object": e.target.files[0]
      });
    }
  }

  subCargaDeDatos = (name) => {
    if(name==="provincia"){
      this.handleCantones();
    }
    if(name==="canton"){
      this.handleParroquias();
    }
  }

  handleSubmmit = (e) => {
    e.preventDefault();
    let {  usuario,tipo_de_reclamo, file_object, comentarios, canal, parroquia, contrato} =this.state;
    if(usuario.trim() < 2 ) {
      alert("Busque y seleccione un usuario");
      return;
    }
    if(parroquia.trim() < 1 ) {
      alert("Escoja la parroquia");
      return;
    }
    if(contrato.trim() < 1 ) {
      alert("Escoja el contrato");
      return;
    }
    if(canal.trim() < 1 ) {
      alert("Seleccione el canal del reclamo");
      return;
    }
    if(tipo_de_reclamo.trim() < 1 ) {
      alert("Seleccione el tipo de reclamo");
      return;
    }

    this.setState({show_loader:true});
      new WebApi().setTicket( this.ITEM.id,
        usuario,tipo_de_reclamo, file_object, comentarios, canal, parroquia, contrato
      ).then(result => {
          this.processsSetTicket(result);
    })
  }

  processsSetTicket = (result) => {
    if (result.no_result) {
        return
    }
    this.setState({show_loader:false});
    let { data } = result;
    if (data.result) {
      this.setState({
        id: data.id,
        mensaje: data.message,
      });
      typeof this.props.handleGetTickets !== "undefined" && this.props.handleGetTickets()
      typeof this.props.hideModal !== "undefined" && this.props.hideModal()
    } else {
        alert(data.message);
    }
  }

  getTicket = () => {
    if(!this.ITEM){
      this.setState({
        show_edit: true,
      });
      return;
    }

    this.setState({
      id: this.ITEM.id,
      provincia: this.ITEM.provincia,
      canton: this.ITEM.canton,
      canal: this.ITEM.canal,
      comentarios: this.ITEM.comentarios,
      contrato: this.ITEM.contrato,
      usuario: this.ITEM.usuario,
      parroquia: this.ITEM.parroquia,
      fecha: this.ITEM.fecha,
      tipo_de_reclamo: this.ITEM.tipo_de_reclamo,
      fecha_solucion: this.ITEM.fecha_solucion,
      detalle_solucion: this.ITEM.detalle_solucion,
      mensaje: "",
    }, ()=>this.handleContratos())
  }

  alertConfirm = ()=> {
    Swal.fire({
        title: 'Confirma',
        text: '¿Estás seguro que deseas eliminarlo?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Si',
        cancelButtonText: 'No, cancelar'
      }).then((result) => {
        if (result.value) {
          this.deleteCanton();
          /*
          Swal.fire(
            'Deleted!',
            'Your imaginary file has been deleted.',
            'success'
          )
        */
        // For more information about handling dismissals please visit
        // https://sweetalert2.github.io/#handling-dismissals
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          console.log("cancelar");
        }
      })
  }

  deleteCanton = () => {
    //return;
    if(!this.ITEM){
      return;
    }
    this.setState({show_loader:true});
    new WebApi().deleteCanton(this.ITEM.id).then(result => {
        this.processdeleteCanton(result);
    })
  }

  processdeleteCanton = (result) => {
    this.setState({show_loader:false});
    if (result.no_result) {
        return
    }
    let { data } = result;
    if (data.result) {
      typeof this.props.handleGetSoportes !== "undefined" && this.props.handleGetSoportes()
      typeof this.props.hideModal !== "undefined" && this.props.hideModal()
    }
  }

  resetForm = () => {
    this.setState({
      id: 0,
      nombre: "",
      provincia: 0,
      canton:0,
      comentario:"",
      usuario:0,
      parroquia:0,
      fecha:"",
      archivo:"",
      tipo_de_reclamo:"",
      canal:"",
      fecha_solucion:"",
      detalle_solucion:"",
      mensaje: "",
    })
  }

  handleTipodeReclamo = () => {
    //console.log(this.state);
    new WebApi().getTiposReclamo().then(result => {
        this.processTipoReclamo(result);
    })
  }

  processTipoReclamo = (result) => {
      if (result.no_result) {
          return
      }
      console.log(result.data);
      let { data } = result;
      if (data.result) {
          this.setState({
            tipos_de_reclamo: data.tipo_de_reclamo
          });
      } else {
          alert(data.message);
      }
  }

  handleProvincias = () => {
      //console.log(this.state);
      new WebApi().getAllProvincias().then(result => {
        //setTimeout(() => {
            this.processProvincias(result);
        //}, 300)
      })
  }

  processProvincias = (result) => {
      if (result.no_result) {
          return
      }
      console.log(result.data);
      let { data } = result;
      if (data.result) {
          this.setState({
            provincias: data.provincias
          },()=> this.state.parroquia>0 && this.handleCantones());
      } else {
          alert(data.message);
      }
  }



  //buscar cantones
  handleCantones = () => {
    this.setState({show_loader:true});
      new WebApi().getCantonesPorProvincia(this.state.provincia).then(result => {
        this.processCantones(result);
      })
  }

  processCantones = (result) => {
      this.setState({show_loader:false});
      if (result.no_result) {
          return
      }
      let { data } = result;
      if (data.result) {
          this.setState({cantones: data.cantones},()=> this.state.canton>0 && this.handleParroquias());
      } else {
          alert(data.message);
      }
  }

  handleParroquias = () => {
    this.setState({show_loader:true});
    new WebApi().getParroquiasPorCanton(this.state.canton).then(result => {
      this.processParroquias(result);
    })
  }

  processParroquias = (result) => {
    this.setState({show_loader:false});
    if (result.no_result) {
        return
    }
    let { data } = result;
    if (data.result) {
        this.setState({parroquias: data.parroquias});
    } else {
        alert(data.message);
    }
  }

  handleContratos = () => {
    this.setState({show_loader:true});
    new WebApi().getContratosPorCliente(this.state.usuario).then(result => {
      this.processContratos(result);
    });
  }

  processContratos = (result) => {
    this.setState({show_loader:false});
    if (result.no_result) {
        return
    }
    let { data } = result;
    if (data.result) {
        this.setState({contratos: data.contratos});
    } else {
        alert(data.message);
    }
  }

}

const mapStateToProps = (state) => {
    return {
        user: state.user_data
    }
}
const actions = {};
export default connect(mapStateToProps, actions)(Ticket);
