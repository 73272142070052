import React,{Component} from 'react';
import { connect } from 'react-redux';
//import {NavLink} from 'react-router-dom';


class TopNavigation extends Component {
  state= {
    isOpenNotificacion: false,
  }

  toogleOpenNotification= () => this.setState({ isOpenNotificacion: !this.state.isOpenNotificacion });

  render(){
    const classNotify = ` ${this.state.isOpenNotificacion ? " show" : ""}`;
    return (
      <nav className="navbar default-layout-navbar col-lg-12 col-12 p-0 fixed-top d-flex flex-row">
        <div className="navbar-menu-wrapper d-flex align-items-stretch">
          <button className="navbar-toggler navbar-toggler align-self-center" type="button" data-toggle="minimize">
            <span className="mdi mdi-chevron-double-left"></span>
          </button>
          <div className="text-center navbar-brand-wrapper d-flex align-items-center justify-content-center">
            <a className="navbar-brand brand-logo-mini" href="index.html"><img src="/assets/images/logo-mini.svg" alt="logo" /></a>
          </div>
          <ul className="navbar-nav">
          { /*
            <li className="nav-item dropdown ml-3">
              <span className="nav-link" id="notificationDropdown" onClick={this.toogleOpenNotification} aria-expanded={this.state.isOpenNotificacion}>
                <i className="mdi mdi-bell-outline"></i>
              </span>
              <div className= {"dropdown-menu dropdown-menu-left navbar-dropdown preview-list "+ classNotify } aria-labelledby="notificationDropdown">
                <h6 className="px-3 py-3 font-weight-semibold mb-0">Notifications</h6>
                <div className="dropdown-divider"></div>
                <a className="dropdown-item preview-item">
                  <div className="preview-thumbnail">
                    <div className="preview-icon bg-success">
                      <i className="mdi mdi-calendar"></i>
                    </div>
                  </div>
                  <div className="preview-item-content d-flex align-items-start flex-column justify-content-center">
                    <h6 className="preview-subject font-weight-normal mb-0">New order recieved</h6>
                    <p className="text-gray ellipsis mb-0"> 45 sec ago </p>
                  </div>
                </a>
                <div className="dropdown-divider"></div>
                <a className="dropdown-item preview-item">
                  <div className="preview-thumbnail">
                    <div className="preview-icon bg-warning">
                      <i className="mdi mdi-settings"></i>
                    </div>
                  </div>
                  <div className="preview-item-content d-flex align-items-start flex-column justify-content-center">
                    <h6 className="preview-subject font-weight-normal mb-0">Server limit reached</h6>
                    <p className="text-gray ellipsis mb-0"> 55 sec ago </p>
                  </div>
                </a>
                <div className="dropdown-divider"></div>
                <a className="dropdown-item preview-item">
                  <div className="preview-thumbnail">
                    <div className="preview-icon bg-info">
                      <i className="mdi mdi-link-variant"></i>
                    </div>
                  </div>
                  <div className="preview-item-content d-flex align-items-start flex-column justify-content-center">
                    <h6 className="preview-subject font-weight-normal mb-0">Kevin karvelle</h6>
                    <p className="text-gray ellipsis mb-0"> 11:09 PM </p>
                  </div>
                </a>
                <div className="dropdown-divider"></div>
                <h6 className="p-3 font-13 mb-0 text-primary text-center">View all notifications</h6>
              </div>
            </li>
            */}
          </ul>
          <ul className="navbar-nav navbar-nav-right">
            <li className="nav-item nav-logout d-none d-lg-block">
              <a className="nav-link" href="/">
                <i className="mdi mdi-home-circle"></i>
              </a>
            </li>
          </ul>
          <button className="navbar-toggler navbar-toggler-right d-lg-none align-self-center" type="button" data-toggle="offcanvas">
            <span className="mdi mdi-menu"></span>
          </button>
        </div>
      </nav>
    )
  }
}

const mapStateToProps = (state) => {
    return {
        user: state.user_data
    }
}

const actions = {

}

export default connect(mapStateToProps, actions)(TopNavigation);


/*<li className="nav-item dropdown">
  <a className="nav-link" id="messageDropdown" href="#" data-toggle="dropdown" aria-expanded="false">
    <i className="mdi mdi-email-outline"></i>
  </a>
  <div className="dropdown-menu dropdown-menu-left navbar-dropdown preview-list" aria-labelledby="messageDropdown">
    <h6 className="p-3 mb-0 font-weight-semibold">Messages</h6>
    <div className="dropdown-divider"></div>
    <a className="dropdown-item preview-item">
      <div className="preview-thumbnail">
        <img src="/assets/images/faces/face1.jpg" alt="profile" className="profile-pic"/>
      </div>
      <div className="preview-item-content d-flex align-items-start flex-column justify-content-center">
        <h6 className="preview-subject ellipsis mb-1 font-weight-normal">Mark send you a message</h6>
        <p className="text-gray mb-0"> 1 Minutes ago </p>
      </div>
    </a>
    <div className="dropdown-divider"></div>
    <a className="dropdown-item preview-item">
      <div className="preview-thumbnail">
        <img src="/assets/images/faces/face6.jpg" alt="Profile" className="profile-pic"/>
      </div>
      <div className="preview-item-content d-flex align-items-start flex-column justify-content-center">
        <h6 className="preview-subject ellipsis mb-1 font-weight-normal">Cregh send you a message</h6>
        <p className="text-gray mb-0"> 15 Minutes ago </p>
      </div>
    </a>
    <div className="dropdown-divider"></div>
    <a className="dropdown-item preview-item">
      <div className="preview-thumbnail">
        <img src="/assets/images/faces/face7.jpg" alt="profile" className="profile-pic"/>
      </div>
      <div className="preview-item-content d-flex align-items-start flex-column justify-content-center">
        <h6 className="preview-subject ellipsis mb-1 font-weight-normal">Profile picture updated</h6>
        <p className="text-gray mb-0"> 18 Minutes ago </p>
      </div>
    </a>
    <div className="dropdown-divider"></div>
    <h6 className="p-3 mb-0 text-center text-primary font-13">4 new messages</h6>
  </div>
</li>*/
