import React,{Component} from 'react';
import WebApi from '../../components/helpers/WebApi';
import {YEAR_COPILATION, meses} from '../../components/helpers/Constantes';
import {CustomInput, ComboBox} from '../../components/common/Parts';
import Spinner from '../../components/common/Spinner';
import { Footer, NewItem} from '../../components/common/Parts';
import RightNavigation from '../../components/common/RightNavigation';
import TopNavigation from '../../components/common/TopNavigation';

import { connect } from 'react-redux';

class ReporteTarifas extends Component {
state ={
  mes:"",
  anio:"",
  meses:[],
  anios:[],
  show_download: false,
}

componentDidMount(){
  this.getYears();
  this.getMeses();
}

render (){
  return (
    <div className="container-scroller">
      {/*<!-- partial:partials/_sidebar.html -->*/}
      <RightNavigation/>
      {/*<!-- partial -->*/}
      <div className="container-fluid page-body-wrapper">
        {/*<!-- partial:partials/_settings-panel.html -->*/}
        {/*<!-- partial -->*/}
        {/*<!-- partial:partials/_navbar.html -->*/}
        <TopNavigation/>

        {/*<!-- partial -->*/}
        <div className="main-panel">
          <div className="content-wrapper pb-0">
            <div class="page-header">
              <h3 class="page-title">Tarifas de Internet Fijo Dedicado</h3>
              <nav aria-label="breadcrumb">
                <ol class="breadcrumb">
                  <li class="breadcrumb-item"><a href="/">Reportes</a></li>
                  <li class="breadcrumb-item active" aria-current="page"> Tarifas </li>
                </ol>
              </nav>
            </div>
            {/*Content here*/}
            {/*
              <h4>Acceda al reporte global de cuentas</h4>
              <div className="row" style={{marginBottom: 40}}>
                <div className="col-sm-4">
                </div>
                <div className="col-sm-4">
                  <a href={new WebApi()._BASE_URL+ new WebApi().REPORTE_TARIFAS_GLOBAL } target="_blank" refer rel="noopener noreferrer" type="button" class="btn btn-danger btn-icon-text">
                        <i class="mdi mdi-download btn-icon-prepend"></i> Descargar
                  </a>
                </div>
              </div>
              <h4>Acceda al reporte mensualizado de cuentas</h4>
            */}
            { this.selectDate()}
            { this.state.show_download &&
              <div className="row">
                <div className="col-sm-4">
                </div>
                <div className="col-sm-4">
                    <a href={new WebApi()._BASE_URL+ new WebApi().REPORTE_TARIFAS
                        + "?mes="+this.state.mes+"&anio="+this.state.anio + "&formato=formato_subir_tarifas"} target="_blank" refer rel="noopener noreferrer" type="button" class="btn btn-danger btn-icon-text">
                          <i class="mdi mdi-download btn-icon-prepend"></i> Descargar
                    </a>
                </div>
              </div>
             }
          </div>
          {/*<!-- content-wrapper ends -->*/}
          {/*<!-- partial:partials/_footer.html -->*/}
          <Footer/>
          {/*<!-- partial -->*/}
        </div>
        {/*<!-- main-panel ends -->*/}
      </div>
      {/*<!--page-body-wrapper ends-->*/}

    </div>
  )

}

  handleChange = (e) => {
    let name=e.target.name;
    this.setState({
        [name]: e.target.value
    });
  }

  selectDate = () =>{
    return (
      <form className="d-flex align-items-center" onSubmit={this.handleSubmit}>
        <div className="col-sm-4">

            <ComboBox
              items={this.state.meses}
              name={"mes"}
              label={"Mes"}
              onChangeText={this.handleChange}
              value={this.state.mes}
              required
            />

        </div>
        <div className="col-sm-4">
          <ComboBox
            items={this.state.anios}
            name={"anio"}
            label={"Año"}
            onChangeText={this.handleChange}
            value={this.state.anio}
            required
          />
        </div>

        <div className="col-sm-4">
          <button className="btn btn-sm btn-primary" type="submit"> Buscar </button>
        </div>

    </form>
  )
}

  getMeses =()=>{
    let months=[];
    meses.forEach((item, i) => {
      months.push({"id":i, "nombre":item});
    });

    this.setState({
      meses: months
    })
  }

  getYears=()=>{
    let years=[];
    for (var i = YEAR_COPILATION; i < YEAR_COPILATION+10; i++){
        years.push({"id":i, "nombre":i});
    }

    this.setState({
      anios: years
    })
  }

  handleSubmit = (e) => {
    e.preventDefault();
    let {mes, anio}= this.state;
    if(mes.trim() ==="" ) {
      alert("Escoja el mes del reporte");
      return;
    }
    if(anio.trim() ==="" ) {
      alert("Escoja el anio del reporte");
      return;
    }
    this.setState({
      show_download: true,
    });

  }




}

const mapStateToProps = (state) => {
    return {
        user: state.user_data
    }
}
const actions = {};
export default connect(mapStateToProps, actions)(ReporteTarifas);
