import React,{Component} from 'react'; //
import PropTypes from 'prop-types';

export default class CustomTextArea extends Component {

  static propTypes = {
    label: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    value: PropTypes.string,
    onChangeText: PropTypes.func.isRequired,
    required: PropTypes.bool,
  };

  focus() {
    this._inputElement.focus()
  }

  render (){
    const {name, label, value, onChangeText, required} = this.props;

    return (
      <div className="form-group">
        <label forhtml={label}>{label}</label>
        {
          required?
          <textarea
            className="form-control"
            id={name}
            name={name}
            placeholder={label}
            onChange={event => onChangeText(event)}
            ref={element => this._inputElement = element}
            value={value}
            required
          />
          :
          <textarea
            className="form-control"
            id={name}
            name={name}
            placeholder={label}
            onChange={event => onChangeText(event)}
            ref={element => this._inputElement = element}
            value={value}
          />
        }
      </div>
    )
  }
}
