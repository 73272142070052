import React,{Component} from 'react';
import WebApi from '../../components/helpers/WebApi';
import { Footer, NewItem} from '../../components/common/Parts';
import RightNavigation from '../../components/common/RightNavigation';
import TopNavigation from '../../components/common/TopNavigation';
import Spinner from '../../components/common/Spinner';
import Swal from 'sweetalert2';
import Paginate from '../../components/common/Pagination';
import {canal,getValueByKey} from '../../components/helpers/Constantes';
import Factura from './Factura';
import Preview from './Preview';
import '../../App.css';
//https://www.npmjs.com/package/react-moment
import moment from 'moment/min/moment-with-locales';
import Moment from 'react-moment';
import NumberFormat from 'react-number-format';

//import 'moment-timezone';
Moment.globalMoment = moment;
Moment.globalLocale = 'es';
Moment.globalFormat = 'dddd DD-MMM-YYYY HH:mm';

class Facturas extends Component {
  PAGE_NUM=1;
  state ={
      search : '',
      facturas: [],
      total: 0,
      perPage: 0,
      item_selected: false,
      showFacturaEdit:false,
      showPreviewFactura: false,
      show_loader: false,
      parroquia:0,
  }

  componentDidMount(){
    this.handleGetFacturas();

  }

  handleChange = (e) => {
      this.setState({
          [e.target.id]: e.target.value
      })
  }

  handleSubmit = (e) => {
      e.preventDefault()
      this.handleGetFacturas();
  }

  handleGetFacturas = () => {
      console.log(this.state);
      this.setState({show_loader:true, facturas: []});
      new WebApi().getFacturas(this.state.search, this.PAGE_NUM, this.state.parroquia).then(result => {
        this.processGetFacturasResponse(result);
      })
  }

  processGetFacturasResponse = (result) => {
      this.setState({show_loader:false});
      if (result.no_result) {
          return
      }
      let { data } = result;
      console.log("datas ", data);
      if (data.result) {

          this.setState({total: data.total, facturas: data.facturas, perPage: data.perPage});
      } else {
          alert(data.message);
      }
  }

  onSelectParroquia = (item) => {
    this.setState({parroquia: item.id},
      ()=>this.handleGetFacturas()
    )
  }

  buscador= ()=> {

    return(
      <form className="d-flex align-items-center" onSubmit={this.handleSubmit}>
        <div className="input-group">
          <div className="input-group-prepend">
            <i className="input-group-text border-0 mdi mdi-magnify"></i>
          </div>
          <input type="text" className="form-control border-0" id="search" name="search" placeholder="Buscar" onChange={this.handleChange}/>
          <div className="input-group-append">
            <button className="btn btn-sm btn-primary" type="submit"> Ir </button>
          </div>
        </div>

      </form>
    )
  }

  table = () => {
    //id 	fecha 	cliente 	email 	direccion 	cedula 	total
      return (
        <React.Fragment>
          <Spinner visible={this.state.show_loader}/>
          <table className="table table-hover table-bordered ">
            <thead>
              <tr>
                <th># Factura</th>
                <th> Fecha </th>
                <th> Cliente </th>
                <th> Total </th>
              </tr>
            </thead>
            <tbody>

            { this.state.facturas.map((item, index)=>
              <tr key={item.id} onClick={()=>this.setState({item_selected:item, showPreviewFactura: true})}>
                <td>{item.id}</td>
                <td><Moment locale="es">{item.fecha}</Moment></td>
                <td>{item.cliente}</td>
                <td>  <NumberFormat  prefix={'$'} displayType={'text'} value={item.total} /></td>
              </tr>
              )
            }
            </tbody>
          </table>
        </React.Fragment>
      )
    }

  hideModal = () => {
    this.setState({
      showFacturaEdit:false,
    })
  }

  render (){
    let adjacents  = 4; //brecha entre páginas después de varios adyacentes
    //let offset = (this.PAGE_NUM - 1) * this.state.perPage;
    let total_pages = Math.ceil(this.state.total/this.state.perPage);

    return (
      <div className="container-scroller">
        {/*<!-- partial:partials/_sidebar.html -->*/}
        <RightNavigation/>
        {/*<!-- partial -->*/}
        <div className="container-fluid page-body-wrapper">
          {/*<!-- partial:partials/_settings-panel.html -->*/}
          {/*<!-- partial -->*/}
          {/*<!-- partial:partials/_navbar.html -->*/}
          <TopNavigation/>

          {/*<!-- partial -->*/}
          <div className="main-panel">
            <div className="content-wrapper pb-0">
            <NewItem
              title="Crear Factura"
              action={()=>this.setState({showFacturaEdit:true, item_selected: false,})}
              title_right="Generar Facturas"
              icon_right="mdi-sync"
              action_right={this.generate}
            />

              {/*Content here*/}
              {this.state.showFacturaEdit &&
                <div className="modal-dialog modal-lg" role="dialog" aria-hidden="false">
                  <div className="modal-content">
                  <div className="modal-header">
                    <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={this.hideModal}>
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  <div className="modal-body" style={{backgroundColor:"#fff"}}>
                    <Factura item={this.state.item_selected}  hideModal={this.hideModal} handleGetFacturas={this.handleGetFacturas}/>
                  </div>
                </div>
              </div>
              }
              {this.state.showPreviewFactura &&
                <div className="modal-dialog modal-lg" role="dialog" aria-hidden="false">
                  <div className="modal-content">
                  <div className="modal-header">
                    <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={()=>this.setState({showPreviewFactura:false })}>
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  <div className="modal-body" style={{backgroundColor:"#fff"}}>
                    <Preview item={this.state.item_selected}  hideModal={()=>this.setState({showPreviewFactura: false})} />
                  </div>
                </div>
              </div>
              }

              {!this.state.showFacturaEdit && this.buscador()}

              {!this.state.showFacturaEdit && this.table()}
              {!this.state.showFacturaEdit &&
                <Paginate tpages={total_pages}  page={this.PAGE_NUM} adjacents={adjacents} load_next={this.load_next}/> }
              {this.state.facturas.length > 0 &&
                <div style={{ textAlign:"right",marginTop: 30 }}>Total de facturas: {this.state.total}</div>
              }
            </div>
            {/*<!-- content-wrapper ends -->*/}
            {/*<!-- partial:partials/_footer.html -->*/}
            <Footer/>
            {/*<!-- partial -->*/}
          </div>
          {/*<!-- main-panel ends -->*/}
        </div>
        {/*<!--page-body-wrapper ends-->*/}

      </div>
    )
  }

  refresh = () => {
    this.PAGE_NUM=1;
    this.handleGetFacturas();
  }

  load_next = (page) => {
    this.PAGE_NUM= page;
    this.handleGetFacturas();
  }

  generate = () => {
    moment.locale('es');
    Swal.fire({
        title: 'Generar facturas de '+moment().format('MMMM'),
        text: 'Generaremos las facturas que todavía no se han emitido este mes',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Si',
        cancelButtonText: 'No, cancelar'
      }).then((result) => {
        if (result.value) {
          this.generateFacturas();
        // For more information about handling dismissals please visit
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          console.log("cancelar");
        }
      })
  }

  generateFacturas = () => {
    this.setState({show_loader:true});
    new WebApi().generateFacturas().then(result => {
      this.processGenerateFacturas(result);
    })
  }

  processGenerateFacturas = (result) => {
    this.setState({show_loader:false});
    if (result.no_result) {
        return
    }
    let { data } = result;
    if (data.result) {
      Swal.fire({
          title: 'Perfecto',
          text: 'Se han generado todas las facturas',
          icon: 'success',
      });
      this.handleGetFacturas();
    } else {
        alert(data.message);
    }
  }
}



export default Facturas;
