import React,{Component} from 'react';
import WebApi from '../../components/helpers/WebApi';
import { Footer, NewItem} from '../../components/common/Parts';
import RightNavigation from '../../components/common/RightNavigation';
import TopNavigation from '../../components/common/TopNavigation';
import Spinner from '../../components/common/Spinner';
import Paginate from '../../components/common/Pagination';
import Provincia from './Provincia';
import '../../App.css';

class Provincias extends Component {
  PAGE_NUM=1;
  state ={
      search : '',
      provincias: [],
      total: 0,
      perPage: 0,
      item_selected: false,
      showProvinciaEdit:false,
      show_loader: false,
  }

  componentDidMount(){
    this.handleGetProvincias();
  }

  handleChange = (e) => {
      this.setState({
          [e.target.id]: e.target.value
      })
  }

  handleSubmit = (e) => {
      e.preventDefault()
      this.handleGetProvincias();
  }

  buscador= ()=> {
    return(
      <form className="d-flex align-items-center" onSubmit={this.handleSubmit}>
        <div className="input-group">
          <div className="input-group-prepend">
            <i className="input-group-text border-0 mdi mdi-magnify"></i>
          </div>
          <input type="text" className="form-control border-0" id="search" name="search" placeholder="Buscar" onChange={this.handleChange}/>
          <div className="input-group-append">
            <button className="btn btn-sm btn-primary" type="submit"> Ir </button>
          </div>
        </div>
      </form>
    )
  }

  table = () => {
      return (
        <React.Fragment>
          <Spinner visible={this.state.show_loader}/>
          <table className="table table-hover table-bordered ">
            <thead>
              <tr>
                <th>Id</th>
                <th> Nombre </th>

              </tr>
            </thead>
            <tbody>
            { this.state.provincias.map((item, index)=>
              <tr key={item.id} onClick={()=>this.setState({item_selected:item, showProvinciaEdit: true})}>
                <td>{item.id}</td>
                <td> {item.nombre }</td>
              </tr>
              )
            }
            </tbody>
          </table>
        </React.Fragment>
      )
    }

  hideModal = () => {
    this.setState({
      showProvinciaEdit:false,
    })
  }

  render (){
    let adjacents  = 4; //brecha entre páginas después de varios adyacentes
    //let offset = (this.PAGE_NUM - 1) * this.state.perPage;
    let total_pages = Math.ceil(this.state.total/this.state.perPage);

    return (
      <div className="container-scroller">
        {/*<!-- partial:partials/_sidebar.html -->*/}
        <RightNavigation/>
        {/*<!-- partial -->*/}
        <div className="container-fluid page-body-wrapper">
          {/*<!-- partial:partials/_settings-panel.html -->*/}
          {/*<!-- partial -->*/}
          {/*<!-- partial:partials/_navbar.html -->*/}
          <TopNavigation/>

          {/*<!-- partial -->*/}
          <div className="main-panel">
            <div className="content-wrapper pb-0">
            <NewItem title="Nueva Provincia" action={()=>this.setState({showProvinciaEdit:true, item_selected:false,})}/>
              {/*Content here*/}
              {this.state.showProvinciaEdit &&
                <div className="modal-dialog modal-lg" role="dialog" aria-hidden="false">
                  <div className="modal-content">
                  <div className="modal-header">
                    <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={this.hideModal}>
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  <div className="modal-body" style={{backgroundColor:"#fff"}}>
                    <Provincia item={this.state.item_selected}  hideModal={this.hideModal} handleGetProvincias={this.handleGetProvincias}/>
                  </div>
                </div>
              </div>
              }
              {!this.state.showProvinciaEdit && this.buscador()}
              {!this.state.showProvinciaEdit && this.table()}
              {!this.state.showProvinciaEdit &&
                <Paginate tpages={total_pages}  page={this.PAGE_NUM} adjacents={adjacents} load_next={this.load_next}/> }
              {this.state.provincias.length > 0 &&
                <div style={{ textAlign:"right",marginTop: 30 }}>Total de provincias: {this.state.total}</div>
              }
            </div>
            {/*<!-- content-wrapper ends -->*/}
            {/*<!-- partial:partials/_footer.html -->*/}
            <Footer/>
            {/*<!-- partial -->*/}
          </div>
          {/*<!-- main-panel ends -->*/}
        </div>
        {/*<!--page-body-wrapper ends-->*/}

      </div>
    )
  }

  refresh = () => {
    this.handleGetProvincias();
  }

  load_next = (page) => {
    this.PAGE_NUM= page;
    this.handleGetProvincias();
  }

  handleGetProvincias = () => {
      console.log(this.state);
      this.setState({show_loader:true});
      new WebApi().getProvincias(this.state.search, this.PAGE_NUM).then(result => {
        //setTimeout(() => {
            this.processGetProvinciasResponse(result);
        //}, 300)
      })
  }

  processGetProvinciasResponse = (result) => {
      if (result.no_result) {
          return
      }
      this.setState({show_loader:false});
      let { data } = result;
      if (data.result) {
          this.setState({total: data.total, provincias: data.provincias, perPage: data.perPage});
      } else {
          alert(data.message);
      }
  }

}



export default Provincias;

/*
<!--<a href="#!" className="forgot-password-link">Forgot password?</a> -->
<!--<p className="login-wrapper-footer-text">Don't have an account? <a href="#!" className="text-reset">Register here</a></p>-->
*/
