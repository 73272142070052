import React,{Component} from 'react';
import WebApi from '../components/helpers/WebApi';
import { connect } from 'react-redux';
import { Footer} from '../components/common/Parts';
import RightNavigation from '../components/common/RightNavigation';
import TopNavigation from '../components/common/TopNavigation';
import '../App.css';
import NumberFormat from 'react-number-format';

class Home extends Component {

  state ={
    total: 0,
    contratos_hoy: 0,
    contratos_mes: 0,
    contratos_total: 0,
    soporte_hoy: 0,
    cobros_mes:0,
    cobros_hoy: 0,
    facturas_mes:0,

    perPage: 0
  }

  componentDidMount(){
    this.getEstadisticas();
  }

  settings = () => {
    return(
       <React.Fragment>
        <div id="settings-trigger"><i className="mdi mdi-settings"></i></div>
        <div id="theme-settings" className="settings-panel">
          <i className="settings-close mdi mdi-close"></i>
          <p className="settings-heading">SIDEBAR SKINS</p>
          <div className="sidebar-bg-options selected" id="sidebar-default-theme">
            <div className="img-ss rounded-circle bg-light border mr-3"></div>Default
          </div>
          <div className="sidebar-bg-options" id="sidebar-dark-theme">
            <div className="img-ss rounded-circle bg-dark border mr-3"></div>Dark
          </div>
          <p className="settings-heading mt-2">HEADER SKINS</p>
          <div className="color-tiles mx-0 px-4">
            <div className="tiles default primary"></div>
            <div className="tiles success"></div>
            <div className="tiles warning"></div>
            <div className="tiles danger"></div>
            <div className="tiles info"></div>
            <div className="tiles dark"></div>
            <div className="tiles light"></div>
          </div>
        </div>
       </React.Fragment>
    )
  }

  render (){
    return (
      <div className="container-scroller">
        {/*<!-- partial:partials/_sidebar.html -->*/}
        <RightNavigation/>
        {/*<!-- partial -->*/}
        <div className="container-fluid page-body-wrapper">
          {/*<!-- partial:partials/_settings-panel.html -->*/}
          {this.settings()}
          {/*<!-- partial -->*/}
          {/*<!-- partial:partials/_navbar.html -->*/}
          <TopNavigation/>
          {/*<!-- partial -->*/}
          <div className="main-panel">
            <div className="content-wrapper pb-0">
              {this.showEstadisticas()}
              {/*Content here*/}
            </div>
            {/*<!-- content-wrapper ends -->*/}
            {/*<!-- partial:partials/_footer.html -->*/}
            <Footer/>
            {/*<!-- partial -->*/}
          </div>
          {/*<!-- main-panel ends -->*/}
        </div>
        {/*<!--page-body-wrapper ends-->*/}
      </div>
    )
  }

  showEstadisticas=()=>{
    /*
    contratos_hoy: data.contratos_hoy,
    contratos_mes: data.contratos_mes,
    contratos_total: data.contratos_total,
    soporte_hoy: data.soporte_hoy,
    cobros_hoy: data.cobros_hoy,
    */
    let {contratos_hoy, contratos_mes, contratos_total,
        soporte_hoy, cobros_hoy, facturas_mes, cobros_mes, cuentas_cobrar}= this.state;
    return (
      <div className="row">
        {this.templateCard("Contratos de hoy", "", contratos_hoy)}
        {this.templateCard("Contratos del mes", "", contratos_mes)}
        {this.templateCard("Total de Contratos", "", contratos_total)}
        {this.templateCard("Tickets de hoy", "Tickets abiertos hoy", soporte_hoy)}
        {this.templateCard("Facturas del mes", "Facturación generada este mes", facturas_mes, true)}
        {this.templateCard("Cuentas por cobrar", "", cuentas_cobrar, true)}
        {this.templateCard("Cobros del mes", "Cobros realizados este mes", cobros_mes, true)}
        {this.templateCard("Cobros de Hoy", "Recaudaciones realizados hoy", cobros_hoy, true)}
      </div>
    )
  }

  templateCard =(label, description, total, number=false)=>{
    return (
      <div className="col-xl-4 grid-margin">
        <div className="card stretch-card mb-3">
          <div className="card-body d-flex flex-wrap justify-content-between">
            <div>
              <h4 className="font-weight-semibold mb-1 text-black"> {label} </h4>
              <h6 className="text-muted">{description}</h6>
            </div>
            <h3 className="text-success font-weight-bold">
              {
                number?
                  <NumberFormat fixedDecimalScale={true} decimalScale={2} prefix={'$'} displayType={'text'} value={total} />
                :
                  <NumberFormat displayType={'text'} value={total} />
              }

            </h3>
          </div>
        </div>
      </div>
    )
  }

  //
  /*getEstadisticas=()=>{
    let fecha= new Date().toDateInputValue();
  }*/

  getEstadisticas = () => {
      this.setState({
          show_loader:true,
          cobros: [],
      });
      new WebApi().getReportesPrincipal().then(result => {
        this.processGetPrincipalResponse(result);
      });
  }

  processGetPrincipalResponse = (result) => {
      this.setState({show_loader:false});
      if (result.no_result) {
          return
      }
      let { data } = result;
      if (data.result) {
          this.setState({
            total: data.total,
            contratos_hoy: data.contratos_hoy,
            contratos_mes: data.contratos_mes,
            contratos_total: data.contratos_total,
            soporte_hoy: data.soporte_hoy,
            cobros_hoy: data.cobros_hoy,
            cobros_mes:data.cobros_hoy,
            cuentas_cobrar: data.cuentas_cobrar,
            facturas_mes: data.facturas_mes,
            perPage: data.perPage
          });
      } else {
          alert(data.message);
      }
  }


}

const mapStateToProps = (state) => {
    return {
        user: state.user_data
    }
}

const actions = {

}


//export default Login;
export default connect(mapStateToProps, actions)(Home);

/*
<!--<a href="#!" className="forgot-password-link">Forgot password?</a> -->
<!--<p className="login-wrapper-footer-text">Don't have an account? <a href="#!" className="text-reset">Register here</a></p>-->
*/
