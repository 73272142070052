import React,{Component} from 'react';
import WebApi from '../../components/helpers/WebApi';
import {CustomInput, ComboBox} from '../../components/common/Parts';
import Spinner from '../../components/common/Spinner';
import { connect } from 'react-redux';
import {IVA} from '../../components/helpers/Constantes';
// ES6 Modules or TypeScript
import Swal from 'sweetalert2';
// CommonJS
//const Swal = require('sweetalert2');
import moment from 'moment/min/moment-with-locales';
import Moment from 'react-moment';
import NumberFormat from 'react-number-format';

//import 'moment-timezone';
Moment.globalMoment = moment;
Moment.globalLocale = 'es';
Moment.globalFormat = 'dddd DD-MMM-YYYY HH:mm';

class Cobro extends Component {
  PAGE_NUM=1;

  state={
    id:"",
    abono:"",
    nombre: "",
    total: "",
    saldo: "",
    saldo_total:"",
    telefono:"",
    email: "",
    show_loader:false,
    mensaje:"",
    facturas:[],
    cobros: [],
    factura_active: true,
    pagos_active: false,
  }

  ITEM=this.props.item || false;

  componentDidMount(){
    this.getCobro();
    this.handleFacturas();
  }



  render(){
    return(
      <form action="#!" onSubmit={this.alertConfirmCobro}>
        {this.state.mensaje!="" && <div className="alert alert-info">{this.state.mensaje}</div>}
        <div className="row">
          <Spinner visible={this.state.show_loader}/>
        </div>
        {this.encabezado()}
        <div className="row">
          <div className="col-sm-6">
            <CustomInput
              name={"abono"}
              label={"Abono"}
              onChangeText={this.handleChange}
              value={this.state.abono}
              required
            />
            <button type="submit" className="btn btn-primary mr-2" > Registrar </button>
            {this.props.hideModal && <button className="btn btn-light mr-2" onClick={this.props.hideModal} type="button" >Cancelar</button>}
            {!this.ITEM && <button className="btn btn-light mr-2" type="button" onClick={this.resetForm}>Limpiar </button>}
          </div>
          <div className="col-sm-6">
          </div>
        </div>
        <div className="col-sm-12" style={{paddingTop:30}}>
        <div className="btn-group" role="group" aria-label="Basic example">
          <button
            type="button"
            className={`btn btn-outline-secondary ${this.state.factura_active ? "active" : ""}  `}
            onClick={this.openFactura}
          >
            Facturas
          </button>
          <button
            type="button"
            className={`btn btn-outline-secondary  ${this.state.pagos_active ? "active": " "} border-left border-left-sm-0`}
            onClick={this.openPagos}
          >
            Pagos
          </button>
        </div>
          <table className="table table-hover table-bordered ">
            <thead>
              <tr>
                <th>#  {this.state.factura_active? "Factura": "Pago"}</th>
                <th> Fecha </th>
                <th> Cliente </th>
                <th> {this.state.factura_active? "Total": "Monto"} sin iva </th>
              </tr>
            </thead>
            <tbody>
            { this.state.facturas.map((item, index)=>
                <tr key={item.id} >
                  <td>{item.id}</td>
                  <td><Moment locale="es">{item.fecha}</Moment></td>
                  <td>{item.cliente}</td>
                  <td>  <NumberFormat fixedDecimalScale={true} decimalScale={2} prefix={'$'} displayType={'text'} value={item.total} /></td>
                </tr>
              )
            }

            { this.state.cobros.map((item, index)=>
                <tr key={item.id} >
                  <td>{item.id}</td>
                  <td><Moment locale="es">{item.fecha}</Moment></td>
                  <td>{item.cliente}</td>
                  <td>  <NumberFormat fixedDecimalScale={true} decimalScale={2}  prefix={'$'} displayType={'text'} value={item.total} /></td>
                </tr>
              )
            }
            </tbody>
          </table>
        </div>
      </form>
    );
  }

  openFactura = () => {
    if(!this.state.factura_active){
      this.setState({
        factura_active:true,
        pagos_active: false,
        cobros:[]
      }, ()=> this.handleFacturas())
    }
  }

  openPagos = () => {
    if(!this.state.pagos_active){
      this.setState({
        factura_active:false,
        pagos_active: true,
        facturas:[]
      }, ()=> this.handlePagos())
    }
  }

  encabezado = () => {
    return (
      <div className="row">
        <div className="col-xl-12 stretch-card grid-margin">
          <div className="card">
            <div className="card-body">
              <div className="d-flex justify-content-between flex-wrap">
                <div>
                  <div className="card-title mb-0">{this.state.nombre}</div>
                  <h3 className="font-weight-bold mb-0">Saldo ${this.state.saldo}</h3>
                </div>
                <div>
                  <div className="d-flex flex-wrap pt-2 justify-content-between sales-header-right">
                    <div className="d-flex mr-5">
                      <button type="button" className="btn btn-social-icon btn-outline-sales">
                        <i className="mdi mdi-inbox-arrow-down"></i>
                      </button>
                      <div className="pl-2">
                        <h4 className="mb-0 font-weight-semibold head-count"> {this.state.total} </h4>
                        <span className="font-10 font-weight-semibold text-muted">TOTAL FACTURAS</span>
                      </div>
                    </div>
                    <div className="d-flex mr-3 mt-2 mt-sm-0">
                      <button type="button" className="btn btn-social-icon btn-outline-sales profit">
                        <i className="mdi mdi-cash text-info"></i>
                      </button>
                      <div className="pl-2">
                        <h4 className="mb-0 font-weight-semibold head-count"> {this.ITEM.valor} </h4>
                        <span className="font-10 font-weight-semibold text-muted">TOTAL PAGADO</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <p className="text-muted font-13 mt-2 mt-sm-0"> {this.state.telefono}- {this.state.email}</p>

            </div>
          </div>
        </div>

      </div>
    )
  }

  calculateIva = (valor) => {
    let iva_calc= 1+(IVA/100);
    return parseFloat(valor*iva_calc).toFixed(2);
  }

  handleChange = (e) => {
    let name=e.target.name;
    this.setState({
        [name]: e.target.value.replace(",",".")
    }, ()=>this.subCargaDeDatos(name));
  }

  subCargaDeDatos = (name) => {
    if(name==="abono"){

      if(parseFloat(this.state.abono)>parseFloat(this.state.saldo)){
        alert("No puede abonar más dinero del saldo pendiente");
        this.setState({abono:""})
      }
    }
  }

  handleSubmmit = () => {
    //e.preventDefault();
    let {id, abono} =this.state;
    //this.props.user.user.id es el usuario actual de la sesión
    this.setState({show_loader:true});
      new WebApi().setCobro( id, this.ITEM.usuario_Id, abono, this.props.user.user.id).then(result => {
        setTimeout(() => {
            this.processsSetCobro(result);
        }, 300)
    })
  }

  processsSetCobro = (result) => {
    if (result.no_result) {
        return
    }
    this.setState({show_loader:false});
    let { data } = result;
    if (data.result) {
      this.setState({
        id: data.id,
        mensaje: data.message,
        abono: "",
      });
      typeof this.props.handleGetCobros !== "undefined" && this.props.handleGetCobros()
      typeof this.props.hideModal !== "undefined" && this.props.hideModal()
    } else {
        alert(data.message);
    }
  }

  getCobro = () => {
    if(!this.ITEM){
      return;
    }
    this.setState({
      id: this.ITEM.id,
      nombre:this.ITEM.nombre+ " "+ this.ITEM.apellido,
      telefono: this.ITEM.telefono,
      email: this.ITEM.email,
      total: this.calculateIva(this.ITEM.total),
      saldo: parseFloat(this.calculateIva(this.ITEM.total)-this.calculateIva(this.ITEM.valor)).toFixed(2),
    })
  }

  alertConfirmCobro = (e)=> {
    e.preventDefault();
    Swal.fire({
        title: '¿Estas seguro?',
        text: 'Esta operación es irreversible',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Si',
        cancelButtonText: 'No, cancelar'
      }).then((result) => {
        if (result.value) {
          this.handleSubmmit();
          /*
          Swal.fire(
            'Deleted!',
            'Your imaginary file has been deleted.',
            'success'
          )
        */
        // For more information about handling dismissals please visit
        // https://sweetalert2.github.io/#handling-dismissals
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          console.log("cancelar");
        }
      })
  }


  alertConfirm = ()=> {
    Swal.fire({
        title: 'Confirma',
        text: '¿Estás seguro que deseas eliminarlo?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Si',
        cancelButtonText: 'No, cancelar'
      }).then((result) => {
        if (result.value) {
          this.deleteCobro();
          /*
          Swal.fire(
            'Deleted!',
            'Your imaginary file has been deleted.',
            'success'
          )
        */
        // For more information about handling dismissals please visit
        // https://sweetalert2.github.io/#handling-dismissals
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          console.log("cancelar");
        }
      })
  }

  deleteCobro = () => {
    //return;
    if(!this.ITEM){
      return;
    }
    this.setState({show_loader:true});
    new WebApi().deleteCobro(this.ITEM.id).then(result => {
        this.processdeleteCobro(result);
    })
  }

  processdeleteCobro = (result) => {
    this.setState({show_loader:false});
    if (result.no_result) {
        return
    }
    let { data } = result;
    if (data.result) {
      typeof this.props.handleGetCobros !== "undefined" && this.props.handleGetCobros()
      typeof this.props.hideModal !== "undefined" && this.props.hideModal()
    }
  }

  resetForm = () => {
    this.setState({
      id: 0,
      nombre: "",
      provincia: 0,
      mensaje: "",
    })
  }

  handleFacturas = () => {
      this.setState({show_loader:true});
      new WebApi().getFacturasPorCliente(this.ITEM.usuario_Id,this.PAGE_NUM).then(result => {
        //setTimeout(() => {
            this.processFacturas(result);
        //}, 300)
      })
  }

  processFacturas = (result) => {
      this.setState({show_loader:false});
      if (result.no_result) {
          return
      }
      console.log(result.data);
      let { data } = result;
      if (data.result) {
          this.setState({
            facturas: data.facturas
          });
      } else {
          alert(data.message);
      }
  }

  //buscar cantones
  handlePagos = () => {
    this.setState({show_loader:true});
      new WebApi().getPagos(this.ITEM.usuario_Id, this.PAGE_NUM).then(result => {
        this.processPagos(result);
      })
  }

  processPagos = (result) => {
      this.setState({show_loader:false});
      if (result.no_result) {
          return
      }
      let { data } = result;
      if (data.result) {
          this.setState({cobros: data.cobros});
      } else {
          alert(data.message);
      }
  }


}

const mapStateToProps = (state) => {
    return {
        user: state.user_data
    }
}
const actions = {};
export default connect(mapStateToProps, actions)(Cobro);
