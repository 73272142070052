import React,{Component} from 'react';
import WebApi from '../../components/helpers/WebApi';
import {CustomInput} from '../../components/common/Parts';
import CustomTextArea from '../../components/common/CustomTextArea';
import Spinner from '../../components/common/Spinner';
import { ReactSearchAutocomplete } from "react-search-autocomplete";

// ES6 Modules or TypeScript
import Swal from 'sweetalert2';
// CommonJS
//const Swal = require('sweetalert2');
import { connect } from 'react-redux';

class Contrato extends Component {
  state={
    id:"",
    comentario:"",
    cliente: 0,
    clientes:[],
    vence: "",
    tarifas: [],
    tarifa: 0,
    nombre_tarifa: "",
    nodos:[],
    nodo:0,
    nombre_nodo: "",
    show_loader:false,
    show_edit: false,
    mensaje:"",
    comentarios:"",
  }

  ITEM=this.props.item || false;

  componentDidMount(){
    this.getContrato();

  }


  render(){
    return(
      <React.Fragment>
        {this.edit()}
      </React.Fragment>
    )

  }

  //<button className="btn btn-danger" type="button" onClick={this.alertConfirm} >Eliminar</button>
  //{!this.ITEM && <button className="btn btn-light mr-2" type="button" onClick={this.resetForm}>Limpiar </button>}

  edit = () => {
    return(
      <form action="#!" onSubmit={this.handleSubmmit}>
        {this.state.mensaje && <div className="alert alert-info">{this.state.mensaje}</div>}
        <div className="row">
          <Spinner visible={this.state.show_loader}/>
          <div className="col-sm-6">
            <div className="form-group">
              <label forhtml="cliente">Cliente: </label>
              {this.ITEM ?
                <span><br/>{this.ITEM.cliente}</span>
                :
                <ReactSearchAutocomplete
                  items={this.state.clientes}
                  onSearch={this.handleOnSearch}
                  onSelect={this.handleOnSelect}
                  onFocus={this.handleOnFocus}
                  autoFocus
                />
              }
            </div>
            <div className="form-group">
              <label forhtml="cliente">Tarifa: </label>
                <ReactSearchAutocomplete
                  items={this.state.tarifas}
                  onSearch={this.handleOnSearchTarifa}
                  onSelect={this.handleOnSelectTarifa}
                  placeholder={this.state.nombre_tarifa}
                  autoFocus
                />
            </div>
            <div className="form-group">
              <label forhtml="cliente">Nodo: </label>
                <ReactSearchAutocomplete
                  items={this.state.nodos}
                  onSearch={this.handleOnSearchNodo}
                  onSelect={this.handleOnSelectNodo}
                  placeholder={this.state.nombre_nodo}
                  autoFocus
                />
            </div>

          </div>
          <div className="col-sm-6">
            <CustomInput
              name={"vence"}
              label={"Finalización Contrato"}
              type="date"
              onChangeText={this.handleChange}
              value={this.state.vence}
              required
            />
            <CustomTextArea
              name={"comentarios"}
              label={"Comentarios"}
              onChangeText={this.handleChange}
              value={this.state.comentarios}
              required
            />
            <button type="submit" className="btn btn-primary mr-2"> Guardar </button>
            {!this.ITEM && <button className="btn btn-light mr-2" type="button" onClick={this.resetForm}>Limpiar </button>}
            {this.ITEM && <button className="btn btn-danger" type="button" onClick={this.alertConfirm} >Eliminar</button>}
            {this.props.hideModal && <button className="btn btn-light mr-2" onClick={this.props.hideModal} type="button" >Cancelar</button>}
          </div>
        </div>
      </form>
    );
  }

  handleChange = (e) => {
    let name=e.target.name;
    this.setState({
        [name]: e.target.value
    });
  }



  handleSubmmit = (e) => {
    e.preventDefault();
    let {cliente,tarifa, nodo, vence, comentarios} =this.state;
    if(cliente.trim() < 2 ) {
      alert("Busque y seleccione un cliente");
      return;
    }
    if(tarifa.trim() < 1 ) {
      alert("Escoja la tarifa");
      return;
    }
    if(nodo.trim() < 1 ) {
      alert("Escoja el nodo de conexión");
      return;
    }

    this.setState({show_loader:true});
      new WebApi().setContrato( this.ITEM.id,
        cliente, tarifa, nodo, vence, comentarios
      ).then(result => {
          this.processsSetContrato(result);
    })
  }

  processsSetContrato = (result) => {
    if (result.no_result) {
        return
    }
    this.setState({show_loader:false});
    let { data } = result;
    if (data.result) {
      this.setState({
        id: data.id,
        mensaje: data.message,
      });
      typeof this.props.handleGetContratos !== "undefined" && this.props.handleGetContratos()
      typeof this.props.hideModal !== "undefined" && this.props.hideModal()
    } else {
        alert(data.message);
    }
  }

  getContrato = () => {
    if(!this.ITEM){
      return;
    }
    this.setState({
      id: this.ITEM.id,
      cliente: this.ITEM.cliente,
      vence: this.ITEM.vence,
      nombre_tarifa: this.ITEM.nombre_tarifa,
      nombre_nodo: this.ITEM.nombre_nodo,
      comentarios: this.ITEM.comentarios,
      mensaje: "",
    })
  }

  alertConfirm = ()=> {
    Swal.fire({
        title: 'Confirma',
        text: '¿Estás seguro que deseas eliminarlo?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Si',
        cancelButtonText: 'No, cancelar'
      }).then((result) => {
        if (result.value) {
          this.deleteContrato();

        // For more information about handling dismissals please visit
        // https://sweetalert2.github.io/#handling-dismissals
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          console.log("cancelar");
        }
      })
  }

  deleteContrato = () => {
    //return;
    if(!this.ITEM){
      return;
    }
    this.setState({show_loader:true});
    new WebApi().deleteContrato(this.ITEM.id).then(result => {
        this.processdeleteContrato(result);
    })
  }

  processdeleteContrato = (result) => {
    this.setState({show_loader:false});
    if (result.no_result) {
        return
    }
    let { data } = result;
    if (data.result) {
      typeof this.props.handleGetContratos !== "undefined" && this.props.handleGetContratos()
      typeof this.props.hideModal !== "undefined" && this.props.hideModal()
    }
  }

  resetForm = () => {
    this.setState({
      id: 0,
      cliente: 0,
      vence: "",
      nombre_tarifa: "",
      nombre_nodo: "",
      mensaje: "",
      comentarios: "",
    });
  }


  //Empieza la parte de las funciones para ReactSearchAutocomplete

    handleOnSearch = (string, cached) => {
      // onSearch returns the string searched and if
      // the values are cached. If the values are cached
      // "cached" contains the cached values, if not, returns false
      console.log(string, cached);
      this.setState({show_loader:true});
        new WebApi().getSearch( string , 1).then(result => {
          this.processsSearch(result);
      });
    }

    processsSearch = (result) => {
      this.setState({show_loader:false});
      if (result.no_result) {
          return
      }
      let { data } = result;
      if (data.result) {
        this.setState({clientes: data.usuarios})
      }
      console.log(data.clientes);
    }

    handleOnSelect = item => {
      // the item selected
      this.setState({
        cliente:item.id
      });
      console.log(item);
    }

    handleOnFocus = () => {
      console.log("Focused");
    }

    handleOnSearchTarifa = (string, cached) => {
      console.log(string, cached);
      this.setState({show_loader:true});
        new WebApi().getSearchTarifas( string , 1).then(result => {
          this.processSearchTarifa(result);
      });
    }

    processSearchTarifa = (result) => {
      this.setState({show_loader:false});
      if (result.no_result) {
          return
      }
      let { data } = result;
      if (data.result) {
        this.setState({tarifas: data.tarifas})
      }
      //console.log(data.clientes);
    }

    handleOnSelectTarifa = item => {
      // the item selected
      this.setState({
        tarifa:item.id
      });
      //console.log(item);
    }

    handleOnSearchNodo = (string, cached) => {
      console.log(string, cached);
      this.setState({show_loader:true});
        new WebApi().getSearchNodos( string , 1).then(result => {
          this.processSearchNodos(result);
      });
    }

    processSearchNodos = (result) => {
      this.setState({show_loader:false});
      if (result.no_result) {
          return
      }
      let { data } = result;
      if (data.result) {
        this.setState({nodos: data.nodos})
      }
      //console.log(data.clientes);
    }

    handleOnSelectNodo = item => {
      // the item selected
      this.setState({
        nodo:item.id
      });
      //console.log(item);
    }
}


const mapStateToProps = (state) => {
    return {
        user: state.user_data
    }
}
const actions = {};
export default connect(mapStateToProps, actions)(Contrato);
