import axios from 'axios';
import FormData from 'form-data';

//Esta es la librería que se usa para hacer y recibir todas las peticiones post y get al API
export default class WebApi {

    //todas las url y direcciones de la API
    SHOW_ALERT= true;
    _URL_APP = "https://gestion.tecmesh.com.ec/";
    _IMAGES_URL = "https://gestion.tecmesh.com.ec/api/images/";
    _BASE_URL = "https://gestion.tecmesh.com.ec/api/"; //api
    CANTONES_POR_PROVINCIA= "cantones_por_provincia.json";
    CANTONES = "cantones.json";
    CANTON_SET= "canton_set.json";
    CANTON_DELETE="canton_eliminar.json";
    COBROS= "cobros.json";
    COBROS_ARQUEO= "cobros_arqueo.json";
    COBROS_INDIVIDUALES= "cobros_individuales.json";
    COBROS_CAJERO= "cobros_cajero.json";
    CONTRATOS_CLIENTE= "contratos_cliente.json";
    COBRO_DELETE = "cobro_delete.json";
    COBRO_SET= "cobro_set.json";
    CONTRATOS= "contratos.json";
    CONTRATO_SET= "contrato_set.json";
    CONTRATO_DELETE = "contrato_delete.json";
    FACTURAS="facturas.json";
    FACTURA_SET= "factura_set.json";
    FACTURA_DETALLE= "factura_detalle.json";
    FACTURA_GENERATE= "factura_generate.json";
    HISTORIES= "ticket_historia.json";
    NODOS= "nodos.json";
    NODOS_SET= "nodos_set.json";
    NODO_ELIMINAR= "nodos_eliminar.json";
    NODOS_SEARCH= "nodos_search.json";
    PARROQUIAS_POR_CANTON= "parroquia_por_canton.json";
    PARROQUIAS ="parroquias.json";
    PARROQUIA_SET= "parroquia_set.json";
    PARROQUIA_DELETE="parroquia_eliminar.json";
    PROVINCIAS= "provincias.json";
    PROVINCIA_SET= "provincia_set.json";
    PROVINCIA_DELETE="provincia_eliminar.json";
    PERMISSIONS= "permissions.json";
    TARIFAS= "tarifas.json";
    TARIFAS_SEARCH= "tarifas_search.json";
    TARIFA_SET = "tarifa_set.json";
    TARIFA_ELIMINAR = "tarifa_eliminar.json";
    TECNOLOGIAS= "tecnologias.json";
    TICKETS= "tickets.json";
    TICKET_SET= "ticket_set.json";
    TICKET_HISTORY_SET="ticket_history_set.json";
    TICKETS_SOLUTION= "ticket_solution.json";
    TIPO_DE_RECLAMO="tipo_de_reclamo.json";
    USER_LOGIN = "login.json";
    USER_RECOVERY = "recovery_password.json"
    USER_SET_PROFILE = "usuario_set.json";
    USER_GET_PROFILE = "usuario_get.json";
    USER_SIGNUP = "register.json";
    USERS = "usuarios.json";
    USERS_CLIENTES = "clientes.json";
    USER_GET= "get_user.json";
    USERS_SEARCH= "usuarios_search.json";
    USERS_ELIMINAR="usuario_eliminar.json";
    REPORTES_PRINCIPAL="rep_principal.json";
    REPORTE_RELACION_CLIENTE= "rep_relacion_cliente.xls";
    REPORTE_AVERIAS= "rep_averias.xls";
    REPORTE_CAPACIDAD_INTERNET= "rep_capacidad_internet.xls";
    REPORTE_USUARIOS= "rep_Lineas.xls";
    REPORTE_USUARIOS_GLOBAL= "rep_Lineas_global.xls";
    REPORTE_TARIFAS= "rep_Tarifas.xls";

    async sendGetRequest(_url, _params) {
        if(!navigator.onLine){
          alert("Al parecer tiene problemas con su conexión a internet");
          let err = [];
          err.error = "Al parecer tiene problemas con su conexión a internet";
          err.no_result = true;
          return err;
        }
        _url = this._BASE_URL + _url;
        console.log("API _url", _url)

        try {
            let response = await axios.get(_url, {
                data: _params ? _params : null,
                timeout: 15000
            });

            console.log("API call response", response)
            return response;

        } catch (error) {
            let err = [];
            err.error = error;
            err.no_result = true;
            console.log("catch error on ", _url, " call fail", err)
            setTimeout(() => {
                this.SHOW_ALERT &&
                alert("No se han encontrado resultados");
                console.log("No se han encontrado resultados");
            }, 400)
            return err;
        }
    }

    async sendPostRequest(_url, _params) {
        if(!navigator.onLine){
          alert("Al parecer tiene problemas con su conexión a internet");
          let err = [];
          err.error = "Al parecer tiene problemas con su conexión a internet";
          err.no_result = true;
          return err;
        }
        _url = this._BASE_URL + _url;
        console.log("API _url", _url);

        if (!_params) {
            _params = {}
        }
        try {
            let response = await axios({
                method: 'post',
                url: _url,
                data: _params,
                timeout: 15000
            });
            console.log("API call response", response);
            return response;

        } catch (error) {
            let err = [];
            err.error = error;
            err.no_result = true;
            console.log("catch error on ", _url, " call fail", err)
            setTimeout(() => {
                this.SHOW_ALERT &&
                alert("No hay resultados para mostrar");
            }, 400)
            return err;
        }
    }

    async sendPostFileRequest(_url, _params,document) {
      if(!navigator.onLine){
        alert("Al parecer tiene problemas con su conexión a internet");
        let err = [];
        err.error = "Al parecer tiene problemas con su conexión a internet";
        err.no_result = true;
        return err;
      }
      _url = this._BASE_URL + _url;
      console.log("API _url", _url);

      const formData = new FormData();

      if(typeof document.name!=="undefined"){
        formData.append('file', document, document.name);
      }

      //formData.append('Content-Type', 'application/pdf');
      for (const prop in _params){
         formData.append(`${prop}`, `${_params[prop]}`);
      }
      console.log("params", formData);
      try {
         let response = await axios({
               method: 'post',
               url: _url,
               data: formData,
               timeout: 15000,
               headers: {
               'Content-Type': 'multipart/form-data',
               }
           });

          console.log("API call response", response);
          return response;
      } catch (error) {
          let err = [];
          err.error = error;
          err.no_result = true;
          console.log("catch error on ", _url, " call fail", err);
          setTimeout(() => {
              this.SHOW_ALERT && alert('No se han encontrado resultados');
          }, 400)
          return err;
      }
    }

    editProfile(user_id,
      nombre, apellido, email, direccion, cedula, telefono,
            parroquia, canton,provincia, permiso){
        let url= this.USER_SET_PROFILE +
            "?user_id=" + user_id +
            "&nombre=" + nombre +
            "&apellido=" + apellido +
            "&email=" + email +
            "&direccion=" + direccion +
            "&cedula=" + cedula +
            "&telefono=" + telefono +
            "&canton=" + canton +
            "&parroquia=" + parroquia +
            "&provincia=" + provincia +
            "&permiso="+permiso
            ;
            return this.sendGetRequest(url)
    }

    getProfile(id){
        let url = this.USER_GET_PROFILE + "?id="+id
        return this.sendGetRequest(url)
    }

    userLogin(_email, _password) {
        //?email=mail@ejemplo.com&pass=xxxxxx
        let url = this.USER_LOGIN + "?email=" + _email + "&pass=" + _password;
        return this.sendGetRequest(url)
    }

    getClientes(search, pag) {
        let url = this.USERS_CLIENTES + `?search=${search}&pag=${pag}`;
        return this.sendGetRequest(url);
    }

    getUsers(search, pag) {
        let url = this.USERS + `?search=${search}&pag=${pag}`;
        return this.sendGetRequest(url);
    }

    //BUSCAR CLIENTES PARA  AUTOCOMPLETE
    getSearch(search, pag) {
        let url = this.USERS_SEARCH + `?search=${search}&pag=${pag}&autocomplete=true`;
        return this.sendGetRequest(url);
    }
    //FUNCTION BUSCAR TARIFAS PARA AUTOCOMPLETE
    getSearchTarifas(search, pag) {
        let url = this.TARIFAS_SEARCH + `?search=${search}&pag=${pag}&autocomplete=true`;
        return this.sendGetRequest(url);
    }

    //FUNCTION BUSCAR NODOS PARA AUTOCOMPLETE
    getSearchNodos(search, pag) {
        let url = this.NODOS_SEARCH + `?search=${search}&pag=${pag}&autocomplete=true`;
        return this.sendGetRequest(url);
    }

    getUser(id) {
        let url = this.USER_GET + `?idUser=${id}`;
        return this.sendGetRequest(url);
    }

    deleteUser(id){
        let url = this.USERS_ELIMINAR + `?user_id=${id}`;
        return this.sendGetRequest(url);
    }

    getTiposReclamo(id,search="", page=1) {
        let url = this.TIPO_DE_RECLAMO + `?search=${search}&pag=${page}`;
        return this.sendGetRequest(url);
    }

    getTecnologias(id,search="", page=1) {
        let url = this.TECNOLOGIAS + `?search=${search}&pag=${page}`;
        return this.sendGetRequest(url);
    }

    //nod_Id`, `nod_Nombre`, `nod_Direccion`, `par_Id`, `nod_Latitud`, `nod_Longitud`
    getTarifas(search,page,parroquia){
      let url = this.TARIFAS + `?search=${search}&pag=${page}&parroquia=${parroquia}`;
      return this.sendGetRequest(url);
    }

    setTarifa(id, nombre, precio, subida, bajada, tipo, tecnologia, comparticion, vigencia,
      parroquia, observaciones, tipo_conexion, tipo_enlace, capacidad){
      let url = this.TARIFA_SET ;
      let body= `id=${id}&nombre=${nombre}&precio=${precio}
        &subida=${subida}&bajada=${bajada}&tipo=${tipo}&tecnologia=${tecnologia}
        &comparticion=${comparticion}&parroquia=${parroquia}&vigencia=${vigencia}&observaciones=${observaciones}
        &tipo_conexion=${tipo_conexion}&tipo_enlace=${tipo_enlace}&capacidad=${capacidad}`;
      return this.sendPostRequest(url, body);
    }

    deleteTarifa(id){
        let url = this.TARIFA_ELIMINAR + `?id=${id}`;
        return this.sendGetRequest(url);
    }

    getTickets(search,page){
      let _url = this.TICKETS +`?search=${search}&pag=${page}`;
      return this.sendGetRequest(_url);
    }

    getTicketHistory(id,page){
      let url = this.HISTORIES + `?status=A&id=${id}&getAll=true`;
      return this.sendGetRequest(url);
    }

    setTicketHistory(id, descripcion, archivo, user, origen){
      let _url = this.TICKET_HISTORY_SET;
      let _params= {
        'id': id,
        'descripcion':descripcion,
        'usuario': user,
        'origen': origen,
      };
      return this.sendPostFileRequest(_url, _params,archivo);
    }

    finishTicket(id, solucion){
      let _url = this.TICKETS_SOLUTION +`?id=${id}&solucion=${solucion}`;
      return this.sendGetRequest(_url);
    }

    setTicket(id, usuario, tipo_reclamo, archivo, comentarios, canal, parroquia, contrato){
      let _url = this.TICKET_SET;
      let _params= {
        'id': id,
        'usuario':usuario,
        'tipo_reclamo': tipo_reclamo,
        'comentarios': comentarios,
        'canal': canal,
        'parroquia': parroquia,
        'contrato': contrato,
      };
      return this.sendPostFileRequest(_url, _params,archivo);
    }

    getNodos(search,page, parroquia){
      let url = this.NODOS + `?search=${search}&pag=${page}&parroquia=${parroquia}`;
      return this.sendGetRequest(url);
    }

    setNodo(id,nombre, direccion, parroquia, latitud, longitud, nodo_anterior){
      let url = this.NODOS_SET ;
      let body= `id=${id}&nombre=${nombre}&direccion=${direccion}
        &parroquia=${parroquia}&latitud=${latitud}&longitud=${longitud}&nodo_anterior=${nodo_anterior}`;
      return this.sendPostRequest(url, body);
    }

    deleteNodo(id){
        let url = this.NODO_ELIMINAR + `?id=${id}`;
        return this.sendGetRequest(url);
    }

    getProvincias(search, page){
      let url = this.PROVINCIAS + `?status=A&search=${search}&pag=${page}`;
      return this.sendGetRequest(url);
    }

    setProvincia(id,nombre){
      let url = this.PROVINCIA_SET ;
      let body= `id=${id}&nombre=${nombre}`;
      return this.sendPostRequest(url, body);
    }

    deleteProvincia(id){
        let url = this.PARROQUIA_DELETE + `?id=${id}`;
        return this.sendGetRequest(url);
    }

    getPermissions(){
      let url = this.PERMISSIONS + `?status=A`;
      return this.sendGetRequest(url);
    }

    getAllProvincias(){
      let url = this.PROVINCIAS + `?status=A&getAll=true`;
      return this.sendGetRequest(url);
    }

    getCantones(search,page){
      let url = this.CANTONES + `?search=${search}&pag=${page}`;
      return this.sendGetRequest(url);
    }

    setCanton(id,nombre, provincia){
      let url = this.CANTON_SET ;
      let body= `id=${id}&nombre=${nombre}&provincia=${provincia}`;
      return this.sendPostRequest(url, body);
    }

    deleteCanton(id){
        let url = this.CANTON_DELETE + `?id=${id}`;
        return this.sendGetRequest(url);
    }

    getCantonesPorProvincia(provincia_Id){
      let url = this.CANTONES_POR_PROVINCIA + `?provincia=${provincia_Id}`;
      return this.sendGetRequest(url);
    }

    getParroquias(search, page){
      let url = this.PARROQUIAS + `?&search=${search}&pag=${page}`;
      return this.sendGetRequest(url);
    }

    setParroquia(id,nombre, canton){
      let url = this.PARROQUIA_SET ;
      let body= `id=${id}&nombre=${nombre}&canton=${canton}`;
      return this.sendPostRequest(url, body);
    }

    deleteParroquia(id){
        let url = this.PARROQUIA_DELETE + `?id=${id}`;
        return this.sendGetRequest(url);
    }

    getParroquiasPorCanton(canton_Id){
      let url = this.PARROQUIAS_POR_CANTON + `?canton=${canton_Id}`;
      return this.sendGetRequest(url);
    }

    getContratos(search, pag) {
        let url = this.CONTRATOS + `?search=${search}&pag=${pag}`;
        return this.sendGetRequest(url);
    }
    getContratosPorCliente(usuario) {
        let url = this.CONTRATOS_CLIENTE + `?cliente=${usuario}`;
        return this.sendGetRequest(url);
    }
    setContrato (id, cliente, tarifa, nodo, vence, comentarios){
      let url = this.CONTRATO_SET;
      let body= `id=${id}&cliente=${cliente}&tarifa=${tarifa}
        &nodo=${nodo}&vence=${vence}&comentarios=${comentarios}`;
      return this.sendPostRequest(url, body);
    }

    deleteContrato(id){
      let url = this.CONTRATO_DELETE + `?id=${id}`;
      return this.sendGetRequest(url);
    }

    deleteCobro(id){
        let url = this.COBRO_DELETE + `?id=${id}`;
        return this.sendGetRequest(url);
    }

    getCobros(search, pag) {
        let url = this.COBROS + `?search=${search}&pag=${pag}`;
        return this.sendGetRequest(url);
    }

    getCobrosArqueo(dia, pag){
      let url = this.COBROS_ARQUEO + `?fecha=${dia}&pag=${pag}`;
      return this.sendGetRequest(url);
    }

    //COBROS_CAJERO
    getCobrosCajero(cajero, fecha, pag){
      let url = this.COBROS_CAJERO + `?cajero=${cajero}&fecha=${fecha}&pag=${pag}`;
      return this.sendGetRequest(url);
    }

    generateFacturas(){
      let url = this.FACTURA_GENERATE + `?code=IOEO~209244"42@`;
      return this.sendGetRequest(url);
    }

    getPagos(usuario, pag){
      let url = this.COBROS_INDIVIDUALES + `?search=&pag=${pag}&usuario=${usuario}`;
      return this.sendGetRequest(url);
    }

    getFacturasPorCliente(usuario, pag){
      let url = this.FACTURAS + `?search=&pag=${pag}&usuario=${usuario}`;
      return this.sendGetRequest(url);
    }

    setCobro(id, cliente, abono, usuario ){
      let url= this.COBRO_SET ;
      let body= `id=${id}&usuario=${cliente}&abono=${abono}&cajero=${usuario}`;
      return this.sendPostRequest(url, body);
    }

    getFacturas(search, pag) {
        let url = this.FACTURAS + `?search=${search}&pag=${pag}`;
        return this.sendGetRequest(url);
    }

    getFacturaDetalle(id){
      let url = this.FACTURA_DETALLE + `?id=${id}`;
      return this.sendGetRequest(url);
    }

    setFactura(id, cliente, detalle){
      let url= this.FACTURA_SET ;
      let body= `id=${id}&cliente=${cliente}&detalle=${detalle}`;
      return this.sendPostRequest(url, body);
    }

    descodificarEntidad(str) {
        return str.replace(/&#(\d+);/g, function(match, dec) {
          return String.fromCharCode(dec);
        });
    }

    getReportesPrincipal(){
      let url = this.REPORTES_PRINCIPAL;
      return this.sendGetRequest(url);
    }

}
