import React,{Component} from 'react';
//import logo from './logo.svg';
import Routes from './components/Router.js'
//import './App.css';
import {Provider} from 'react-redux';
import {createStore,applyMiddleware} from 'redux';
import reducers from './components/redux/reducers';
import ReduxThunk from 'redux-thunk';

class App extends Component{
  render() {
    console.disableYellowBox = true;
    const store = createStore(reducers, {}, applyMiddleware(ReduxThunk));
    return (
      <Provider store={store} >
          <Routes />
      </Provider>
    );
  }


}

export default App;

/*function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>
          Edit <code>src/App.js</code> and save to reload.
        </p>
        <a
          className="App-link"
          href="https://reactjs.org"
          target="_blank"
          rel="noopener noreferrer"
        >
          Learn React
        </a>
      </header>
    </div>
  );
}*/
