import React,{Component} from 'react';
import WebApi from '../../components/helpers/WebApi';
import {CustomInput, ComboBox} from '../../components/common/Parts';
import Spinner from '../../components/common/Spinner';
// ES6 Modules or TypeScript
import Swal from 'sweetalert2';
// CommonJS
//const Swal = require('sweetalert2');

import { connect } from 'react-redux';

const emailRegex  =
  /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;

class UserForm extends Component {
  state={
    id:"",
    nombre:"",
    apellido:"",
    direccion:"",
    cedula:"",
    telefono:"",
    provincia:0,
    canton:0,
    parroquia:0,
    permiso: 0,
    mensaje:"",
    provincias:[],
    cantones: [],
    parroquias: [],
    datos_usuario: [],
    permisos: [],
    show_loader:false,

  }

  USER=this.props.item || false;

  componentDidMount(){
    this.getUser();
    this.handleProvincias();
    this.handlePermission();
  }



  render(){
    return(
      <form action="#!" onSubmit={this.handleSubmmit}>

        {this.state.mensaje!="" && <div className="alert alert-info">{this.state.mensaje}</div>}
        <div className="row">
          <Spinner visible={this.state.show_loader}/>
          <div className="col-sm-6">
            <CustomInput
              name={"nombre"}
              label={"Nombre"}
              onChangeText={this.handleChange}
              value={this.state.nombre}
              required
            />
            <CustomInput
              name={"apellido"}
              label={"Apellido"}
              onChangeText={this.handleChange}
              value={this.state.apellido}
              required
            />
            <CustomInput
              name={"cedula"}
              label={"Cédula"}
              onChangeText={this.handleChange}
              value={this.state.cedula}
              required
            />
            <CustomInput
              name={"direccion"}
              label={"Dirección"}
              onChangeText={this.handleChange}
              value={this.state.direccion}
              required
            />
            <CustomInput
              name={"telefono"}
              label={"Telefono"}
              onChangeText={this.handleChange}
              value={this.state.telefono}
              required
            />
            <CustomInput
              name={"email"}
              label={"Email"}
              onChangeText={this.handleChange}
              value={this.state.email}
              required
            />

          </div>
          <div className="col-sm-6">
            <ComboBox
              items={this.state.provincias}
              name={"provincia"}
              label={"Provincia"}
              onChangeText={this.handleChange}
              value={this.state.provincia}
              required
            />
            <ComboBox
              items={this.state.cantones}
              name={"canton"}
              label={"Cantón"}
              onChangeText={this.handleChange}
              value={this.state.canton}
              required
            />
            <ComboBox
              items={this.state.parroquias}
              name={"parroquia"}
              label={"Parroquia"}
              onChangeText={this.handleChange}
              value={this.state.parroquia}
              required
            />
            <ComboBox
              items={this.state.permisos}
              name={"permiso"}
              label={"Permiso de Acceso"}
              onChangeText={this.handleChange}
              value={this.state.permiso}
              required
            />
            <button type="submit" className="btn btn-primary mr-2"> Guardar </button>
            {this.props.hideModal && <button className="btn btn-light mr-2" onClick={this.props.hideModal} type="button" >Cancelar</button>}
            {this.USER && this.USER!=this.props.user.user.id &&
              <button className="btn btn-danger" type="button" onClick={this.alertConfirm} >Eliminar</button>}
            {!this.USER && <button className="btn btn-light mr-2" type="button" onClick={this.resetForm}>Limpiar </button>}
          </div>
        </div>
      </form>
    );
  }

  handleChange = (e) => {
    let name=e.target.name;
    this.setState({
        [name]: e.target.value
    }, ()=>this.subCargaDeDatos(name));
  }

  subCargaDeDatos = (name) => {
    if(name=="provincia"){
      this.handleCantones();
    }
    if(name=="canton"){
      this.handleParroquias();
    }
  }

  handleSubmmit = (e) => {
    e.preventDefault();
    let { id, nombre, apellido, email, direccion, cedula, telefono,
          parroquia, canton,provincia, permiso} =this.state;
    if(nombre.trim() < 2 ) {
      alert("Escriba el nombre");
      return;
    }
    if(apellido.trim() < 2 ) {
      alert("Escriba el apellido");
      return;
    }
    if(email.trim() < 2 || !emailRegex.test(email)) {
      alert("Escriba un email válido");
      return;
    }
    if(cedula.trim() < 2 ) {
      alert("Escriba la cedula");
      return;
    }
    if(telefono.trim() < 2 ) {
      alert("Escriba el teléfono");
      return;
    }
    if(parroquia.trim() < 1 ) {
      alert("Escoja la parroquia");
      return;
    }
    if(canton.trim() < 1 ) {
      alert("Escoja el cantón");
      return;
    }
    if(provincia.trim() < 1 ) {
      alert("Escoja la provincia");
      return;
    }
    if(permiso.trim() < 1 ) {
      alert("Escoja el permiso de acceso");
      return;
    }

    this.setState({show_loader:true});
      new WebApi().editProfile( this.USER, nombre, apellido, email, direccion, cedula, telefono,
            parroquia, canton,provincia, permiso).then(result => {
        setTimeout(() => {
            this.processSetUser(result);
            typeof this.props.handleGetUsers !== "undefined" && this.props.handleGetUsers()
            typeof this.props.hideModal !== "undefined" && this.props.hideModal()
        }, 300)
    })
  }

  processSetUser = (result) => {
    if (result.no_result) {
        return
    }
    this.setState({show_loader:false});
    let { data } = result;
    if (data.result) {
      this.setState({
        id: data.id,
        mensaje: data.message,
      });

    } else {
        alert(data.message);
    }
  }

  getUser = () => {
    if(!this.USER){
      return;
    }
    this.setState({show_loader:true});
    new WebApi().getUser(this.USER).then(result => {
      setTimeout(() => {
          this.processGetUserResponse(result);
      }, 300)
    })
  }

  processGetUserResponse = (result) => {
    this.setState({show_loader:false});
    if (result.no_result) {
        return
    }
    let { data } = result;
    if (data.result) {
        //se redirecciona automaticamente
        data=data.data;
        this.setState({
          id:data.id,
          nombre: data.nombre,
          apellido: data.apellido,
          email: data.email,
          direccion: data.direccion,
          cedula: data.cedula,
          telefono: data.telefono,
          parroquia: data.parroquia,
          canton: data.canton,
          provincia: data.provincia,
          permiso: data.permiso,
        }, ()=> this.state.canton>0 && this.handleCantones())

    } else {
        alert(data.message);
    }
  }

  alertConfirm = ()=> {
    Swal.fire({
        title: 'Confirma',
        text: '¿Estás seguro que deseas eliminarlo?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Si',
        cancelButtonText: 'No, cancelar'
      }).then((result) => {
        if (result.value) {
          this.deleteUser();
          /*
          Swal.fire(
            'Deleted!',
            'Your imaginary file has been deleted.',
            'success'
          )
        */
        // For more information about handling dismissals please visit
        // https://sweetalert2.github.io/#handling-dismissals
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          console.log("cancelar");
        }
      })
  }

  deleteUser = () => {
    //return;
    if(!this.USER){
      return;
    }
    this.setState({show_loader:true});
    new WebApi().deleteUser(this.USER).then(result => {
        this.processDeleteUser(result);
    })
  }

  processDeleteUser = (result) => {
    this.setState({show_loader:false});
    if (result.no_result) {
        return
    }
    let { data } = result;
    if (data.result) {
      typeof this.props.handleGetUsers !== "undefined" && this.props.handleGetUsers()
      typeof this.props.hideModal !== "undefined" && this.props.hideModal()
    }
  }

  resetForm = () => {
    this.setState({
      id: 0,
      nombre: "",
      apellido: "",
      email: "",
      direccion: "",
      cedula: "",
      telefono: "",
      parroquia: 0,
      canton: 0,
      provincia: 0,
      permiso: 0,
      mensaje: "",
    })
  }

  handleProvincias = () => {
      //console.log(this.state);
      new WebApi().getAllProvincias().then(result => {
        //setTimeout(() => {
            this.processProvincias(result);
        //}, 300)
      })
  }

  processProvincias = (result) => {
      if (result.no_result) {
          return
      }
      console.log(result.data);
      let { data } = result;
      if (data.result) {
          this.setState({
            provincias: data.provincias
          },()=> this.state.parroquia>0 && this.handleCantones());
      } else {
          alert(data.message);
      }
  }

  //buscar cantones
  handleCantones = () => {
    this.setState({show_loader:true});
      new WebApi().getCantonesPorProvincia(this.state.provincia).then(result => {
        this.processCantones(result);
      })
  }

  processCantones = (result) => {
      this.setState({show_loader:false});
      if (result.no_result) {
          return
      }
      let { data } = result;
      if (data.result) {
          this.setState({cantones: data.cantones},()=> this.state.canton>0 && this.handleParroquias());
      } else {
          alert(data.message);
      }
  }

  handleParroquias = () => {
    this.setState({show_loader:true});
    new WebApi().getParroquiasPorCanton(this.state.canton).then(result => {
      this.processParroquias(result);
    })
  }

  processParroquias = (result) => {
    this.setState({show_loader:false});
    if (result.no_result) {
        return
    }
    let { data } = result;
    if (data.result) {
        this.setState({parroquias: data.parroquias});
    } else {
        alert(data.message);
    }
  }

  handlePermission  = () => {
    new WebApi().getPermissions().then(result => {
      this.processPermission(result);
    })
  }

  processPermission = (result) => {
    if (result.no_result) {
        return
    }
    let { data } = result;
    if (data.result) {
      this.setState({permisos: data.permisos});
    } else {
      alert(data.message);
    }
  }



}

const mapStateToProps = (state) => {
    return {
        user: state.user_data
    }
}
const actions = {};
export default connect(mapStateToProps, actions)(UserForm);
