import React,{Component} from 'react';
import WebApi from '../../components/helpers/WebApi';
import { Footer, NewItem} from '../../components/common/Parts';
import RightNavigation from '../../components/common/RightNavigation';
import TopNavigation from '../../components/common/TopNavigation';
import Spinner from '../../components/common/Spinner';
//import EscogerParroquia from '../../components/common/EscogerParroquia';
import Paginate from '../../components/common/Pagination';
import {canal,getValueByKey} from '../../components/helpers/Constantes';
import Ticket from './Ticket';
import '../../App.css';
//https://www.npmjs.com/package/react-moment
import moment from 'moment/min/moment-with-locales';
import Moment from 'react-moment';

//import 'moment-timezone';
Moment.globalMoment = moment;
Moment.globalLocale = 'es';
Moment.globalFormat = 'dddd DD-MMM-YYYY HH:mm';

class Soportes extends Component {
  PAGE_NUM=1;
  state ={
      search : '',
      soportes: [],
      total: 0,
      perPage: 0,
      item_selected: false,
      showCantonEdit:false,
      show_loader: false,
      parroquia:0,
  }

  componentDidMount(){
    this.handleGetSoportes();

  }

  handleChange = (e) => {
      this.setState({
          [e.target.id]: e.target.value
      })
  }

  handleSubmit = (e) => {
      e.preventDefault()
      this.handleGetSoportes();
  }

  handleGetSoportes = () => {
      console.log(this.state);
      this.setState({show_loader:true, soportes: []});
      new WebApi().getTickets(this.state.search, this.PAGE_NUM, this.state.parroquia).then(result => {
        this.processGetSoportesResponse(result);
      })
  }

  processGetSoportesResponse = (result) => {
      this.setState({show_loader:false});
      if (result.no_result) {
          return
      }
      let { data } = result;
      console.log("datas ", data);
      if (data.result) {

          this.setState({total: data.total, soportes: data.soporte, perPage: data.perPage});
      } else {
          alert(data.message);
      }
  }

  onSelectParroquia = (item) => {
    this.setState({parroquia: item.id},
      ()=>this.handleGetSoportes()
    )
  }

  buscador= ()=> {

    return(
      <form className="d-flex align-items-center" onSubmit={this.handleSubmit}>
        <div className="input-group">
          <div className="input-group-prepend">
            <i className="input-group-text border-0 mdi mdi-magnify"></i>
          </div>
          <input type="text" className="form-control border-0" id="search" name="search" placeholder="Buscar" onChange={this.handleChange}/>
          <div className="input-group-append">
            <button className="btn btn-sm btn-primary" type="submit"> Ir </button>
          </div>
        </div>

      </form>
    )
  }

  table = () => {

      return (
        <React.Fragment>
          <Spinner visible={this.state.show_loader}/>
          <table className="table table-hover table-bordered ">
            <thead>
              <tr>
                <th>Id</th>
                <th> Usuario </th>
                <th> Nombre Parroquia </th>
                <th> Tipo de Reclamo </th>
                <th> Canal </th>
              </tr>
            </thead>
            <tbody>
            { this.state.soportes.map((item, index)=>
              <tr key={item.id} onClick={()=>this.setState({item_selected:item, showCantonEdit: true})}>
                <td>{item.id}</td>
                <td>{item.nombre_usuario}</td>
                <td> {item.nombre_parroquia}</td>
                <td><Moment locale="es">{item.fecha}</Moment></td>
                <td> {getValueByKey(item.canal, canal)}</td>
              </tr>
              )
            }
            </tbody>
          </table>
        </React.Fragment>
      )
    }

  hideModal = () => {
    this.setState({
      showCantonEdit:false,
    })
  }

  render (){
    let adjacents  = 4; //brecha entre páginas después de varios adyacentes
    //let offset = (this.PAGE_NUM - 1) * this.state.perPage;
    let total_pages = Math.ceil(this.state.total/this.state.perPage);

    return (
      <div className="container-scroller">
        {/*<!-- partial:partials/_sidebar.html -->*/}
        <RightNavigation/>
        {/*<!-- partial -->*/}
        <div className="container-fluid page-body-wrapper">
          {/*<!-- partial:partials/_settings-panel.html -->*/}
          {/*<!-- partial -->*/}
          {/*<!-- partial:partials/_navbar.html -->*/}
          <TopNavigation/>

          {/*<!-- partial -->*/}
          <div className="main-panel">
            <div className="content-wrapper pb-0">
            <NewItem title="Crear Ticket" action={()=>this.setState({showCantonEdit:true, item_selected: false,})}/>
              {/*Content here*/}
              {this.state.showCantonEdit &&
                <div className="modal-dialog modal-lg" role="dialog" aria-hidden="false">
                  <div className="modal-content">
                  <div className="modal-header">
                    <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={this.hideModal}>
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  <div className="modal-body" style={{backgroundColor:"#fff"}}>
                    <Ticket item={this.state.item_selected}  hideModal={this.hideModal} handleGetSoportes={this.handleGetSoportes}/>
                  </div>
                </div>
              </div>
              }
              {!this.state.showCantonEdit && this.buscador()}

              {!this.state.showCantonEdit && this.table()}
              {!this.state.showCantonEdit &&
                <Paginate tpages={total_pages}  page={this.PAGE_NUM} adjacents={adjacents} load_next={this.load_next}/> }
              {this.state.soportes.length > 0 &&
                <div style={{ textAlign:"right",marginTop: 30 }}>Total de soportes: {this.state.total}</div>
              }
            </div>
            {/*<!-- content-wrapper ends -->*/}
            {/*<!-- partial:partials/_footer.html -->*/}
            <Footer/>
            {/*<!-- partial -->*/}
          </div>
          {/*<!-- main-panel ends -->*/}
        </div>
        {/*<!--page-body-wrapper ends-->*/}

      </div>
    )
  }

  refresh = () => {
    this.handleGetSoportes();
  }

  load_next = (page) => {
    this.PAGE_NUM= page;
    this.handleGetSoportes();
  }



}



export default Soportes;
