import React,{Component} from 'react';
import WebApi from '../../components/helpers/WebApi';
import {CustomInput, ComboBox} from '../../components/common/Parts';
import Spinner from '../../components/common/Spinner';
import { connect } from 'react-redux';
import {IVA} from '../../components/helpers/Constantes';
// ES6 Modules or TypeScript
import Swal from 'sweetalert2';
// CommonJS
//const Swal = require('sweetalert2');
import moment from 'moment/min/moment-with-locales';
import Moment from 'react-moment';
import NumberFormat from 'react-number-format';

//import 'moment-timezone';
Moment.globalMoment = moment;
Moment.globalLocale = 'es';
Moment.globalFormat = 'dddd DD-MMM-YYYY HH:mm';

class ArqueoDetalle extends Component {
  PAGE_NUM=1;

  state={
    id:"",
    nombre:"",
    total: "",
    fecha: "",
    cajero: 0,
    show_loader:false,
    mensaje:"",
    facturas:[],
    cobros: [],
    factura_active: true,
    pagos_active: false,
  }

  ITEM=this.props.item || false;

  componentDidMount(){
    this.getCobro();
    this.handlePagos();
  }

  render(){
    return(
      <div>
        {this.state.mensaje!="" && <div className="alert alert-info">{this.state.mensaje}</div>}
        <div className="row">
          <Spinner visible={this.state.show_loader}/>
        </div>
        {this.encabezado()}
        <div className="row">
          <div className="col-sm-12">
            <h4>Detalle de cobros </h4>
          </div>
        </div>
        <div className="col-sm-12" style={{paddingTop:30}}>
        <table className="table table-hover table-bordered ">
            <thead>
              <tr>
                <th>#  {"Pagos"}</th>
                <th> Fecha </th>
                <th> Cliente </th>
                <th> {"Total"} sin iva </th>
              </tr>
            </thead>
            <tbody>
            { this.state.cobros.map((item, index)=>
                <tr key={item.id} >
                  <td>{item.id}</td>
                  <td><Moment locale="es">{item.fecha}</Moment></td>
                  <td>{item.nombre}</td>
                  <td>  <NumberFormat fixedDecimalScale={true} decimalScale={2} prefix={'$'} displayType={'text'} value={item.total} /></td>
                </tr>
              )
            }
            </tbody>
          </table>
        </div>
      </div>
    );
  }


  encabezado = () => {
    return (
      <div className="row">
        <div className="col-xl-12 stretch-card grid-margin">
          <div className="card">
            <div className="card-body">
              <div className="d-flex justify-content-between flex-wrap">
                <div>
                  <div className="card-title mb-0">{this.state.nombre}</div>
                  <h3 className="font-weight-bold mb-0">Fecha: <Moment locale="es">{this.state.fecha}</Moment></h3>
                </div>
                <div>
                  <div className="d-flex flex-wrap pt-2 justify-content-between sales-header-right">
                    <div className="d-flex mr-5">
                      <div className="pl-2">
                      </div>
                    </div>
                    <div className="d-flex mr-3 mt-2 mt-sm-0">
                      <button type="button" className="btn btn-social-icon btn-outline-sales profit">
                        <i className="mdi mdi-cash text-info"></i>
                      </button>
                      <div className="pl-2">
                        <h4 className="mb-0 font-weight-semibold head-count">
                          <NumberFormat  prefix={'$'} displayType={'text'} value={this.state.total} fixedDecimalScale={true} decimalScale={2} />
                        </h4>
                        <span className="font-10 font-weight-semibold text-muted">TOTAL </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <p className="text-muted font-13 mt-2 mt-sm-0"> </p>

            </div>
          </div>
        </div>

      </div>
    )
  }

  calculateIva = (valor) => {
    let iva_calc= 1+(IVA/100);
    return parseFloat(valor*iva_calc).toFixed(2);
  }

  getCobro = () => {
    if(!this.ITEM){
      return;
    }
    this.setState({
      id: this.ITEM.id,
      nombre: this.ITEM.nombre_cajero,
      total: this.ITEM.total,
      fecha: this.ITEM.fecha,
      cajero: this.ITEM.cajero,
    })
  }

  resetForm = () => {
    this.setState({
      id: 0,
      nombre:"",
      total: "",
      fecha: "",
      cajero: 0,
    })
  }

  //buscar cantones
  handlePagos = () => {
    this.setState({show_loader:true});
    new WebApi().getCobrosCajero(this.ITEM.cajero, this.ITEM.fecha, this.PAGE_NUM).then(result => {
      this.processPagos(result);
    });
  }

  processPagos = (result) => {
      this.setState({show_loader:false});
      if (result.no_result) {
          return
      }
      let { data } = result;
      if (data.result) {
          this.setState({cobros: data.cobros});
      } else {
          alert(data.message);
      }
  }
}

const mapStateToProps = (state) => {
    return {
        user: state.user_data
    }
}
const actions = {};
export default connect(mapStateToProps, actions)(ArqueoDetalle);
