import React,{Component} from 'react';
import WebApi from '../../components/helpers/WebApi';
import {tipos, tipo_conexion, tipo_enlace} from '../../components/helpers/Constantes';
import {CustomInput, ComboBox} from '../../components/common/Parts';
import CustomTextArea from '../../components/common/CustomTextArea';
import Spinner from '../../components/common/Spinner';
// ES6 Modules or TypeScript
import Swal from 'sweetalert2';
// CommonJS
//const Swal = require('sweetalert2');
import { connect } from 'react-redux';

class Tarifa extends Component {
  state={
    id:"",
    nombre:"",
    precio:"",
    subida: "",
    bajada: "",
    tipo:"",
    tecnologia:"",
    capacidad: "",
    comparticion: "",
    vigencia:"",
    observaciones:"",
    tipo_conexion:"",
    tipo_enlace:"",
    conexion:"",
    provincia: 0,
    canton:0,
    parroquia:0,
    mensaje:"",
    tecnologias:[],
    provincias:[],
    cantones: [],
    parroquias: [],
    show_loader:false,

  }

  ITEM=this.props.item || false;

  componentDidMount(){
    this.getTarifa();
    this.handleProvincias();
    this.getTecnologias();
  }

  //`tar_Nombre`, `tar_Precio`, `tar_Velocidad_Subida`, `tar_Velocidad_Bajada`, `tar_Tipo`,
   // `tar_Tecnologia`, `tar_Comparticion`, `tar_Vigencia`, `par_Id`, `tar_Observaciones`,
   // `tar_Tipo_Conexion`, `tar_Tipo_Enlace`
  render(){
    return(
      <form action="#!" onSubmit={this.handleSubmmit}>
        {this.state.mensaje!=="" && <div className="alert alert-info">{this.state.mensaje}</div>}
        <div className="row">
          <Spinner visible={this.state.show_loader}/>
          <div className="col-sm-6">
            <CustomInput
              name={"nombre"}
              label={"Nombre"}
              onChangeText={this.handleChange}
              value={this.state.nombre}
              required
            />
            <CustomInput
              name={"precio"}
              label={"Precio"}
              onChangeText={this.handleChange}
              value={this.state.precio}
              required
            />
            <CustomInput
              name={"subida"}
              label={"Velocidad de Subida (kbps)"}
              onChangeText={this.handleChange}
              value={this.state.subida}
              required 
            />
            <CustomInput
              name={"bajada"}
              label={"Velocidad de Bajada (kbps)"}
              onChangeText={this.handleChange}
              value={this.state.bajada}
              required
            />
            <ComboBox
              items={tipos}
              name={"tipo"}
              label={"Tipo de Tarifa"}
              onChangeText={this.handleChange}
              value={this.state.tipo}
              required
            />
            <ComboBox
              items={tipo_enlace}
              name={"tipo_enlace"}
              label={"Tipo de Enlace"}
              onChangeText={this.handleChange}
              value={this.state.tipo_enlace}
              required
            />
            <ComboBox
              items={this.state.tecnologias}
              name={"tecnologia"}
              label={"Tecnología"}
              onChangeText={this.handleChange}
              value={this.state.tecnologia}
              required
            />
            <ComboBox
              items={tipo_conexion}
              name={"tipo_conexion"}
              label={"Tipo de Conexión"}
              onChangeText={this.handleChange}
              value={this.state.tipo_conexion}
              required
            />
          </div>
          <div className="col-sm-6">
            <CustomInput
              items={this.state.capacidad}
              name={"capacidad"}
              label={"Capacidad en Kbps"}
              onChangeText={this.handleChange}
              value={this.state.capacidad}
              required
            />
            <CustomInput
              items={this.state.comparticion}
              name={"comparticion"}
              label={"Comparticion"}
              onChangeText={this.handleChange}
              value={this.state.comparticion}
              required
            />
            <CustomInput
              name={"vigencia"}
              label={"Vigencia"}
              onChangeText={this.handleChange}
              value={this.state.vigencia}
              type="date"
              required
            />
            <ComboBox
              items={this.state.provincias}
              name={"provincia"}
              label={"Provincia"}
              onChangeText={this.handleChange}
              value={this.state.provincia}
              required
            />
            <ComboBox
              items={this.state.cantones}
              name={"canton"}
              label={"Canton"}
              onChangeText={this.handleChange}
              value={this.state.canton}
              required
            />
            <ComboBox
              items={this.state.parroquias}
              name={"parroquia"}
              label={"Parroquia"}
              onChangeText={this.handleChange}
              value={this.state.parroquia}
              required
            />
            <CustomTextArea

              name={"observaciones"}
              label={"Observaciones"}
              onChangeText={this.handleChange}
              value={this.state.observaciones}
              required
            />
            <button type="submit" className="btn btn-primary mr-2"> Guardar </button>
            {this.props.hideModal && <button className="btn btn-light mr-2" onClick={this.props.hideModal} type="button" >Cancelar</button>}
            {this.ITEM &&
              <button className="btn btn-danger" type="button" onClick={this.alertConfirm} >Eliminar</button>}
            {!this.ITEM && <button className="btn btn-light mr-2" type="button" onClick={this.resetForm}>Limpiar </button>}
          </div>
        </div>
      </form>
    );
  }

  handleChange = (e) => {
    let name=e.target.name;
    this.setState({
        [name]: e.target.value
    }, ()=>this.subCargaDeDatos(name));
  }

  subCargaDeDatos = (name) => {
    if(name==="provincia"){
      this.handleCantones();
    }
    if(name==="canton"){
      this.handleParroquias();
    }
  }



  handleSubmmit = (e) => {
    e.preventDefault();
    let { nombre, precio, subida, bajada, tipo, tecnologia, comparticion, vigencia,
     parroquia, observaciones, tipo_conexion, tipo_enlace, capacidad} =this.state;
    if(nombre.trim() < 2 ) {
      alert("Escriba el nombre");
      return;
    }
    if(subida.trim() < 1 ) {
      alert("Escriba la velocidad de subida");
      return;
    }
    if(bajada.trim() ==="") {
      alert("Escriba la velocidad de bajada");
      return;
    }
    if(precio.trim() ==="" ) {
      alert("Escoja el precio");
      return;
    }
    if(parroquia.trim() < 1 ) {
      alert("Escoja la parroquia");
      return;
    }

    this.setState({show_loader:true});
      new WebApi().setTarifa( this.ITEM.id,
         nombre, precio, subida, bajada, tipo, tecnologia, comparticion, vigencia,
          parroquia, observaciones, tipo_conexion, tipo_enlace, capacidad
      ).then(result => {
        setTimeout(() => {
            this.processsSetTarifa(result);
        }, 300)
    })
  }

  processsSetTarifa = (result) => {
    if (result.no_result) {
        return
    }
    this.setState({show_loader:false});
    let { data } = result;
    if (data.result) {
      this.setState({
        id: data.id,
        mensaje: data.message,
      });
      typeof this.props.handleGetTarifas !== "undefined" && this.props.handleGetTarifas()
      typeof this.props.hideModal !== "undefined" && this.props.hideModal()
    } else {
        alert(data.message);
    }
  }

  getTecnologias = () => {
    //getTecnologias
    this.setState({show_loader:true});
    new WebApi().getTecnologias(this.state.search, this.PAGE_NUM).then(result => {
      this.processGetTecnologias(result);
    })
  }

  processGetTecnologias = (result) => {
    this.setState({show_loader:false});
    if (result.no_result) {
        return
    }

    let { data } = result;
    if (data.result) {
      this.setState({
        tecnologias: data.tecnologias,
      });

    } else {
        alert(data.message);
    }
  }

  getTarifa = () => {
    if(!this.ITEM){
      return;
    }
    this.setState({
      id: this.ITEM.id,
      nombre:this.ITEM.nombre,
      provincia: this.ITEM.provincia,
      canton: this.ITEM.canton,
      parroquia: this.ITEM.parroquia,
      precio: this.ITEM.precio,
      subida: this.ITEM.subida,
      bajada: this.ITEM.bajada,
      tipo: this.ITEM.tipo,
      tecnologia: this.ITEM.tecnologia,
      comparticion: this.ITEM.comparticion,
      capacidad: this.ITEM.capacidad,
      vigencia: this.ITEM.vigencia,
      observaciones: this.ITEM.observaciones,
      tipo_conexion: this.ITEM.tipo_conexion,
      tipo_enlace: this.ITEM.tipo_enlace,
      nombre_parroquia: this.ITEM.nombre_parroquia
    })
  }



  alertConfirm = ()=> {
    Swal.fire({
        title: 'Confirma',
        text: '¿Estás seguro que deseas eliminarlo?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Si',
        cancelButtonText: 'No, cancelar'
      }).then((result) => {
        if (result.value) {
          this.deleteTarifa();
          /*
          Swal.fire(
            'Deleted!',
            'Your imaginary file has been deleted.',
            'success'
          )
        */
        // For more information about handling dismissals please visit
        // https://sweetalert2.github.io/#handling-dismissals
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          console.log("cancelar");
        }
      })
  }

  deleteCanton = () => {
    //return;
    if(!this.ITEM){
      return;
    }
    this.setState({show_loader:true});
    new WebApi().deleteCanton(this.ITEM.id).then(result => {
        this.processdeleteCanton(result);
    })
  }

  processdeleteCanton = (result) => {
    this.setState({show_loader:false});
    if (result.no_result) {
        return
    }
    let { data } = result;
    if (data.result) {
      typeof this.props.handleGetTarifas !== "undefined" && this.props.handleGetTarifas()
      typeof this.props.hideModal !== "undefined" && this.props.hideModal()
    }
  }

  resetForm = () => {
    this.setState({
      id: 0,
      nombre: "",
      provincia: 0,
      mensaje: "",
      canton: 0,
      parroquia: 0,
      precio: "",
      subida: "",
      bajada: "",
      tipo: "",
      tecnologia: "",
      capacidad: "",
      comparticion: "",
      vigencia: "",
      observaciones: "",
      tipo_conexion: "",
      tipo_enlace: "",
      nombre_parroquia: ""
    })
  }

  handleProvincias = () => {
      //console.log(this.state);
      new WebApi().getAllProvincias().then(result => {
        //setTimeout(() => {
            this.processProvincias(result);
        //}, 300)
      })
  }

  processProvincias = (result) => {
      if (result.no_result) {
          return
      }
      console.log(result.data);
      let { data } = result;
      if (data.result) {
          this.setState({
            provincias: data.provincias
          },()=> this.state.parroquia>0 && this.handleCantones());
      } else {
          alert(data.message);
      }
  }

  //buscar cantones
  handleCantones = () => {
    this.setState({show_loader:true});
      new WebApi().getCantonesPorProvincia(this.state.provincia).then(result => {
        this.processCantones(result);
      })
  }

  processCantones = (result) => {
      this.setState({show_loader:false});
      if (result.no_result) {
          return
      }
      let { data } = result;
      if (data.result) {
          this.setState({cantones: data.cantones},()=> this.state.canton>0 && this.handleParroquias());
      } else {
          alert(data.message);
      }
  }

  handleParroquias = () => {
    this.setState({show_loader:true});
    new WebApi().getParroquiasPorCanton(this.state.canton).then(result => {
      this.processParroquias(result);
    })
  }

  processParroquias = (result) => {
    this.setState({show_loader:false});
    if (result.no_result) {
        return
    }
    let { data } = result;
    if (data.result) {
        this.setState({parroquias: data.parroquias});
    } else {
        alert(data.message);
    }
  }

}

const mapStateToProps = (state) => {
    return {
        user: state.user_data
    }
}
const actions = {};
export default connect(mapStateToProps, actions)(Tarifa);
