
import React from 'react'; //,{Component}
import PropTypes from 'prop-types';
//import '../App.css';

export const Footer = () => {
  return (
    <footer className="footer">
      <div className="d-sm-flex justify-content-center justify-content-sm-between">
        <span className="text-muted text-center text-sm-left d-block d-sm-inline-block">Creado por © 2020 <a href="https://www.procesogeek.com/" target="_blank" rel="noopener noreferrer">Proceso Geek</a>. </span>
      </div>
    </footer>
  )
}

export const NewItem= ({title, action, title_right, action_right, icon_right}) => {
  return(
    <div className="page-header flex-wrap">
      <div className="header-right d-flex flex-wrap mt-2 mt-sm-0">
        <button type="button" className="btn btn-primary mt-2 mt-sm-0 btn-icon-text" onClick={action}>
        <i className="mdi mdi-plus-circle"></i> {title} </button>
      </div>
      {
        title_right &&
        <div className="header-right d-flex flex-wrap mt-2 mt-sm-0">
          <button type="button" style={{backgroundColor: "#1669b4"}} className="btn btn-primary mt-2 mt-sm-0 btn-icon-text" onClick={action_right}>
          {icon_right &&
              <i style={{backgroundColor: "#1D3657"}} className= {" mdi " +icon_right} ></i>
          }
           {title_right}  </button>
        </div>
      }
    </div>
  )
}

//
//props
export class CustomInput extends React.Component {
  static defaultProps = {
      type: "text"
    };
  static propTypes = {
    label: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    value: PropTypes.string,
    onChangeText: PropTypes.func.isRequired,
    required: PropTypes.bool,
  };

  focus() {
    this._inputElement.focus()
  }

  render (){
    const {name, label, value, onChangeText, required, type} = this.props;
    return (
      <div className="form-group">
        <label forhtml={label}>{label}</label>
        {
          required?
          <input
            type={type}
            className="form-control"
            id={name}
            name={name}
            placeholder={label}
            onChange={event => onChangeText(event)}
            ref={element => this._inputElement = element}
            value={value}
            required
          />
          :
          <input
            type={type}
            className="form-control"
            id={name}
            name={name}
            placeholder={label}
            onChange={event => onChangeText(event)}
            ref={element => this._inputElement = element}
            value={value}
          />
        }
      </div>
    )
  }
}

export class ComboBox  extends React.Component {
  static propTypes = {
    label: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    onChangeText: PropTypes.func.isRequired,
    required: PropTypes.bool,
    items:  PropTypes.array,
  };

  focus() {
    this._inputElement.focus()
  }

  render (){
    const {name, label, value, onChangeText, required, items} = this.props;
    return (
      <div className="form-group">
        <label forhtml={label}>{label}</label>
        {
          required?
          <select
            id={name}
            name={name}
            className="form-control"
            onChange={event => onChangeText(event)}
            required
            ref={element => this._inputElement = element}
          >
          <option value="">Seleccione</option>
          {
            items.map((item,index)=>
                value===item.id ?
                <option key={item.id} value={item.id} selected>{item.nombre}</option>:
                <option key={item.id} value={item.id} >{item.nombre}</option>
            )
          }
        </select>
           :
        <select
          id={name}
          name={name}
          className="form-control"
          onChange={event => onChangeText(event)}
          ref={element => this._inputElement = element}
        >
          <option value="">Seleccione</option>
          {
            items.map((item,index)=><option key={item.id} value={item.id}>{item.nombre}</option>)
          }
        </select>
        }
      </div>
    )
  }

}
