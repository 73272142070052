import React,{Component} from 'react';
import WebApi from '../../components/helpers/WebApi';
import {CustomInput} from '../../components/common/Parts';
import Spinner from '../../components/common/Spinner';
// ES6 Modules or TypeScript
import Swal from 'sweetalert2';
// CommonJS
//const Swal = require('sweetalert2');

import { connect } from 'react-redux';

class Provincia extends Component {
  state={
    id:"",
    nombre:"",
    mensaje:"",
    show_loader:false,
  }

  ITEM=this.props.item || false;

  componentDidMount(){
    this.getProvincia();
  }



  render(){
    return(
      <form action="#!" onSubmit={this.handleSubmmit}>

        {this.state.mensaje!=="" && <div className="alert alert-info">{this.state.mensaje}</div>}
        <div className="row">
          <Spinner visible={this.state.show_loader}/>
          <div className="col-sm-6">
            <CustomInput
              name={"nombre"}
              label={"Nombre"}
              onChangeText={this.handleChange}
              value={this.state.nombre}
              required
            />
          </div>
          <div className="col-sm-6">
            <button type="submit" className="btn btn-primary mr-2"> Guardar </button>
            {this.props.hideModal && <button className="btn btn-light mr-2" onClick={this.props.hideModal} type="button" >Cancelar</button>}
            {this.ITEM &&
              <button className="btn btn-danger" type="button" onClick={this.alertConfirm} >Eliminar</button>}
            {!this.ITEM && <button className="btn btn-light mr-2" type="button" onClick={this.resetForm}>Limpiar </button>}
          </div>
        </div>
      </form>
    );
  }

  handleChange = (e) => {
    let name=e.target.name;
    this.setState({
        [name]: e.target.value
    });
  }



  handleSubmmit = (e) => {
    e.preventDefault();
    let {  nombre} =this.state;
    if(nombre.trim() < 2 ) {
      alert("Escriba el nombre");
      return;
    }
    this.setState({show_loader:true});
      new WebApi().setProvincia( this.ITEM.id, nombre).then(result => {
        setTimeout(() => {
            this.processsSetProvincia(result);
            typeof this.props.handleGetProvincias !== "undefined" && this.props.handleGetProvincias()
            typeof this.props.hideModal !== "undefined" && this.props.hideModal()
        }, 300)
    })
  }

  processsSetProvincia = (result) => {
    this.setState({show_loader:false});
    if (result.no_result) {
        return
    }
    let { data } = result;
    if (data.result) {
      this.setState({
        id: data.id,
        mensaje: data.message,
      });

    } else {
        alert(data.message);
    }
  }

  getProvincia = () => {
    if(!this.ITEM){
      return;
    }
    this.setState({
      id: this.ITEM.id,
      nombre: this.ITEM.nombre,
    })
  }



  alertConfirm = ()=> {
    Swal.fire({
        title: 'Confirma',
        text: '¿Estás seguro que deseas eliminarlo?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Si',
        cancelButtonText: 'No, cancelar'
      }).then((result) => {
        if (result.value) {
          this.deleteProvincia();
          /*
          Swal.fire(
            'Deleted!',
            'Your imaginary file has been deleted.',
            'success'
          )
        */
        // For more information about handling dismissals please visit
        // https://sweetalert2.github.io/#handling-dismissals
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          console.log("cancelar");
        }
      })
  }

  deleteParroquia = () => {
    //return;
    if(!this.ITEM){
      return;
    }
    this.setState({show_loader:true});
    new WebApi().deleteProvincia(this.ITEM.id).then(result => {
        this.processdeleteProvincia(result);
    })
  }

  processdeleteProvincia = (result) => {
    this.setState({show_loader:false});
    if (result.no_result) {
        return
    }
    let { data } = result;
    if (data.result) {
      typeof this.props.handleGetProvincias !== "undefined" && this.props.handleGetProvincias()
      typeof this.props.hideModal !== "undefined" && this.props.hideModal()
    }
  }

  resetForm = () => {
    this.setState({
      id: 0,
      nombre: "",
      mensaje: "",
    })
  }





}

const mapStateToProps = (state) => {
    return {
        user: state.user_data
    }
}
const actions = {};
export default connect(mapStateToProps, actions)(Provincia);
