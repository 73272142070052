import React,{Component} from 'react';
import {NavLink} from 'react-router-dom';
import { connect } from 'react-redux';
import { setUserData } from '../../components/redux/actions/ActionsUserData';
import MyStorage from '../../components/helpers/MyStorage';

class RightNavigation extends Component {
  state= {
      isOpenUsers:false,
      isOpenSettings:false,
      isOpenReports: false,
      isOpenFinanzas: false,
  }

  //funciones para mostrar u ocultar submenús
  toggleOpenUsers = () => this.setState({ isOpenUsers: !this.state.isOpenUsers });
  toggleOpenSettings = () => this.setState({ isOpenSettings: !this.state.isOpenSettings });
  toggleOpenReports = () => this.setState({ isOpenReports: !this.state.isOpenReports });
  toggleOpenFinanzas = () => this.setState({ isOpenFinanzas: !this.state.isOpenFinanzas });

  //(cerrar sesión)
  logout = ()  => {
    this.props.setUserData(false);
    new MyStorage().setUserInfo(false);
  }

  render(){
    let {user}=this.props.user;
    let foto= user.foto ||  "/assets/images/faces/user.png";
    // clases para ocultar o mostrar los menus
    const classUsers = ` ${this.state.isOpenUsers ? " show" : ""}`;
    const classSettings = ` ${this.state.isOpenSettings ? " show" : ""}`;
    const classReports = ` ${this.state.isOpenReports ? " show" : ""}`;
    const classFinanzas = ` ${this.state.isOpenFinanzas ? " show" : ""}`;

    return (
      <nav className="sidebar sidebar-offcanvas" id="sidebar">
        <ul className="nav">
          <li className="nav-item nav-profile border-bottom">
            <NavLink to="/perfil" className="nav-link flex-column" >
              <div className="nav-profile-image">
                <img src={foto} alt="profile" />
                {/*<!--change to offline or busy as needed-->*/}
              </div>
              <div className="nav-profile-text d-flex ml-0 mb-3 flex-column">
                <span className="font-weight-semibold mb-1 mt-2 text-center">{user.nombre + " "+user.apellido}</span>
              </div>
            </NavLink>
            <span  onClick={this.logout} style={{ textAlign: "center",  }} > Salir  <i className="mdi mdi-logout"></i></span>
          </li>
          <li className="nav-item pt-3">
            <a className="nav-link d-block" href="/">
              <img className="sidebar-brand-logo" src="/assets/images/logo.png" alt="logo" style={{width:200}} />
              <img className="sidebar-brand-logomini" src="/assets/images/logo-mini.svg" alt="logo"  />
              <div className="small font-weight-light pt-1">Panel de Control</div>
            </a>
          </li>
          <li className="pt-2 pb-1">
            <span className="nav-item-head"> Opciones</span>
          </li>
          <li className="nav-item">
            <NavLink  className="nav-link" to="/" activeClassName='active' exact={true}>
              <i className="mdi mdi-compass-outline menu-icon"></i>
              <span className="menu-title">Dashboard</span>
            </NavLink >
          </li>
          <li className="nav-item">
            <span className="nav-link" onClick={this.toggleOpenUsers} aria-expanded={this.state.isOpenUsers}>
              <i className="mdi mdi-human-greeting  menu-icon"></i>
              <span className="menu-title">Personas</span>
              <i className="menu-arrow"></i>
            </span>
            <div className={"collapse "+ classUsers } id="ui-basic">
              <ul className="nav flex-column sub-menu">
                <li className="nav-item">
                  <NavLink  className="nav-link"  to="/users" activeClassName='active'>Usuarios</NavLink >
                </li>
                <li className="nav-item">
                  <NavLink  className="nav-link"  to="/clientes" activeClassName='active'>Clientes</NavLink >
                </li>
                <li className="nav-item">
                  <NavLink  className="nav-link"  to="/user" activeClassName='active'>Nueva Persona</NavLink >
                </li>
              </ul>
            </div>
          </li>
          <li className="nav-item">
            <span className="nav-link" onClick={this.toggleOpenSettings} aria-expanded={this.state.isOpenSettings}>
              <i className="mdi mdi-crosshairs-gps menu-icon"></i>
              <span className="menu-title">Configuraciones</span>
              <i className="menu-arrow"></i>
            </span>
            <div className={"collapse "+ classSettings } id="ui-basic">
              <ul className="nav flex-column sub-menu">
                <li className="nav-item">
                  <NavLink  className="nav-link"  to="/provincias" activeClassName='active'>Provincias</NavLink >
                </li>
                <li className="nav-item">
                  <NavLink  className="nav-link"  to="/cantones" activeClassName='active'>Cantones</NavLink >
                </li>
                <li className="nav-item">
                  <NavLink  className="nav-link"  to="/parroquias" activeClassName='active'>Parroquias</NavLink >
                </li>
                <li className="nav-item">
                  <NavLink  className="nav-link"  to="/nodos" activeClassName='active'>Nodos</NavLink >
                </li>
                <li className="nav-item">
                  <NavLink  className="nav-link"  to="/tarifas" activeClassName='active'>Tarifas</NavLink >
                </li>
              </ul>
            </div>
          </li>
          <li className="nav-item">
              <NavLink className="nav-link" activeClassName='active' to="/soporte"  rel="noopener noreferrer">
              <i className="mdi mdi-tools menu-icon"></i>
              <span className="menu-title">Soporte</span>
            </NavLink>
          </li>
          <li className="nav-item">
              <NavLink className="nav-link" activeClassName='active' to="/contratos"  rel="noopener noreferrer">
              <i className="mdi mdi-handshake  menu-icon"></i>
              <span className="menu-title">Contratos</span>
            </NavLink>
          </li>
          <li className="nav-item">
              <NavLink className="nav-link" activeClassName='active' to="/facturas"  rel="noopener noreferrer">
              <i className="mdi mdi-file-document-outline menu-icon"></i>
              <span className="menu-title">Facturación</span>
            </NavLink>
          </li>

          <li className="nav-item">
            <span className="nav-link" onClick={this.toggleOpenFinanzas} aria-expanded={this.state.isOpenFinanzas}>
              <i className="mdi mdi-account-cash-outline menu-icon"></i>
              <span className="menu-title">Finanzas</span>
              <i className="menu-arrow"></i>
            </span>
            <div className={"collapse "+ classFinanzas } id="ui-basic">
              <ul className="nav flex-column sub-menu">
                <li className="nav-item">
                  <NavLink  className="nav-link"  to="/cobros" activeClassName='active'>Cobros</NavLink >
                </li>
                <li className="nav-item">
                  <NavLink  className="nav-link"  to="/arqueo" activeClassName='active'>Arqueo de caja</NavLink >
                </li>

              </ul>
            </div>
          </li>
          <li className="nav-item">
            <span className="nav-link" onClick={this.toggleOpenReports} aria-expanded={this.state.isOpenReports}>
              <i className="mdi mdi-crosshairs-gps menu-icon"></i>
              <span className="menu-title">Reportes</span>
              <i className="menu-arrow"></i>
            </span>
            <div className={"collapse "+ classReports } id="ui-basic">
              <ul className="nav flex-column sub-menu">
                <li className="nav-item">
                  <NavLink  className="nav-link"  to="/relacioncliente" activeClassName='active'>Relación Cliente </NavLink >
                </li>
                <li className="nav-item">
                  <NavLink  className="nav-link"  to="/averias" activeClassName='active'>Reporte Averías </NavLink >
                </li>
                <li className="nav-item">
                  <NavLink  className="nav-link"  to="/reporte_usuario" activeClassName='active'>Reporte Usuarios</NavLink >
                </li>
                <li className="nav-item">
                  <NavLink  className="nav-link"  to="/reporte_tarifa" activeClassName='active'>Reporte Tarifas</NavLink >
                </li>
                <li className="nav-item">
                  <NavLink  className="nav-link"  to="/reclamocapacidad" activeClassName='active'>Capacidad Internet</NavLink >
                </li>
              </ul>
            </div>
          </li>
        </ul>
      </nav>
    )
  }
}
/*
<li className="nav-item pt-3">
    <a className="nav-link" href="http://bootstrapdash.com/demo/plus-free/documentation/documentation.html" target="_blank" rel="noopener noreferrer">
    <i className="mdi mdi-file-document-box menu-icon"></i>
    <span className="menu-title">Documentation</span>
  </a>
</li>*/
const mapStateToProps = (state) => {
    return {
        user: state.user_data
    }
}
const actions = {
  setUserData
};
export default connect(mapStateToProps, actions)(RightNavigation);
