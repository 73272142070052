import React,{Component} from 'react';
import WebApi from '../../components/helpers/WebApi';
import {CustomInput} from '../../components/common/Parts';
import CustomTextArea from '../../components/common/CustomTextArea';
import {origen_respuesta, getValueByKey} from '../../components/helpers/Constantes';
import { connect } from 'react-redux';

class Preview extends Component {
  state={
    detalle_solucion: "", //cuando el ticket  ha sido resuelto
    respuesta:"",
    historia: [],
    file_object: "",
    responder: true,
    cerrar_ticket: false,
    archivo: "",
  }
  ITEM=this.props.item || false;

  componentDidMount(){
    this.setDetails();
    this.handleHistory();
  }
  //detalle_solucion
  setDetails = () => {
    this.setState({
      detalle_solucion: this.ITEM.detalle_solucion
    });
  }

  show_Responder = () => {
    this.setState({
      responder:true,
      cerrar_ticket: false,
    })
  }

  show_CerrarTicket = () => {
    this.setState({
      responder:false,
      cerrar_ticket: true,
    })
  }

  render(){
      return(
        <React.Fragment>
          <div class="template-demo">
            <button type="button" onClick={this.show_Responder} class={this.state.responder? "btn btn-inverse-primary btn-fw": "btn btn-inverse-secondary btn-fw" } > Responder </button>
            <button type="button" onClick={this.show_CerrarTicket} class={this.state.cerrar_ticket? "btn btn-inverse-primary btn-fw": "btn btn-inverse-secondary btn-fw"}> Finalizar Ticket </button>
          </div>
          {
            this.state.detalle_solucion==="" ?
            <div className="row">
              <form action="#!" onSubmit={this.handleSubmmit} className="card-body">
                <h3> {this.state.responder? "Responder": "Finalizar Ticket" } </h3>
                {this.state.responder &&
                  <CustomInput
                    type="file"
                    name={"archivo"}
                    label={this.ITEM ? this.state.archivo_subido!==""? "Reemplaza el archivo subido": "Añade un adjunto"   : "Sube un adjunto" }
                    onChangeText={this.handleChange}
                    value={this.state.archivo}

                  />
                }

                <CustomTextArea
                  name={"respuesta"}
                  label={this.state.responder? "Su respuesta": "Cómo se solucionó el problema" }
                  onChangeText={this.handleChange}
                  value={this.state.respuesta}
                  required
                />
                <button type="submit" className="btn btn-primary mr-2"> Enviar </button>
              </form>
            </div>
            :
            <div className="card">
              <div className="card-body">
                <h3>Ticket Resuelto</h3>
                <p>{this.state.detalle_solucion}</p>
              </div>
            </div>
          }

          <div className="card">
            <div className="card-body">
              <h3>Conversación</h3>
                {this.state.historia.map((item, index)=>
                  <div className="card">
                    <div className="card-body" style={{ textAlign: item.origen==="A"? "left": "right" }}>
                      <h5> {item.emisor} -{getValueByKey(item.origen,origen_respuesta)}</h5>
                      <p>{item.descripcion}</p>
                      {item.archivo !==""? <a target="_blank" rel="noopener noreferrer" href={new WebApi()._URL_APP + item.archivo}>Ver adjunto</a> :"" }
                    </div>
                  </div>
                  )
                }
                {this.state.historia.length===0 &&
                  <p>Todavía no hay respuestas al ticket actual</p>
                }
            </div>
          </div>
        </React.Fragment>
      );
  }

  handleChange = (e) => {
    let name=e.target.name;
    this.setState({
        [name]: e.target.value
    });

    if(name==="archivo"){
      this.setState({
        "file_object": e.target.files[0]
      });
    }
  }

  handleSubmmit = (e) => {
    e.preventDefault();
    let {  respuesta, file_object} =this.state;
    if(respuesta.trim() < 2 ) {
      alert("Escriba una respuesta");
      return;
    }

    this.setState({show_loader:true});
    if(this.state.responder){
      new WebApi().setTicketHistory( this.ITEM.id,
          respuesta, file_object, this.props.user.user.id, "S"
        ).then(result => {
            this.processsSetResponse(result);
      })
    } else {
      new WebApi().finishTicket( this.ITEM.id,
          respuesta, file_object
        ).then(result => {
            this.processsSetFinished(result);
      })
    }

  }
  processsSetResponse = (result) => {
    if (result.no_result) {
        return
    }
    this.setState({show_loader:false});
    let { data } = result;
    let {respuesta}= this.state;
    if (data.result) {
      this.setState({
        mensaje: data.message,
        historia: [
          { "id":data.id, soporte: this.ITEM.id,
          descripcion: respuesta,
          archivo: data.archivo,
          emisor: this.props.user.user.nombre + " "+this.props.user.user.apellido,
          origen: "S",
        }, ...this.state.historia]
        ,
        respuesta: "",
        archivo: "",
      });

    } else {
        alert(data.message);
    }
  }

  processsSetFinished = (result) => {
    if (result.no_result) {
        return
    }
    this.setState({show_loader:false});
    let { data } = result;
    if (data.result) {
      this.setState({
        id: data.id,
        mensaje: data.message,
        detalle_solucion: this.state.respuesta,
      });
      typeof this.props.handleGetTickets !== "undefined" && this.props.handleGetTickets()
      typeof this.props.hideModal !== "undefined" && this.props.hideModal()
    } else {
        alert(data.message);
    }
  }

  handleHistory = () => {
      if(!this.ITEM){
        return;
      }
      console.log(this.state);
      this.setState({show_loader:true});
      new WebApi().getTicketHistory(this.ITEM.id,this.PAGE_NUM).then(result => {
            this.processHistory(result);
      })
  }

  processHistory = (result) => {
      if (result.no_result) {
          return
      }
      this.setState({show_loader:false});
      let { data } = result;
      if (data.result) {
          this.setState({total: data.total, historia: data.historia, perPage: data.perPage});
      } else {
          alert(data.message);
      }
  }

}


const mapStateToProps = (state) => {
    return {
        user: state.user_data
    }
}
const actions = {};
export default connect(mapStateToProps, actions)(Preview);
