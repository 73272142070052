import React,{Component} from 'react';
//import PropTypes from 'prop-types';
import Spinner from '../../components/common/Spinner';
import WebApi from '../../components/helpers/WebApi';

export default class CustomTextArea extends Component {

    state={
      mas_filtros: false,
      provincias:[],
      cantones:[],
      parroquias:[],
      show_loader: false,
      parroquia:0,
    }

  componentDidMount(){
    this.handleProvincias();
  }

  showHideEscoger= ()=>{

    let item={"id":0};
    this.setState(prevstate =>({
      mas_filtros:!prevstate.mas_filtros,
      parroquia:0
    }),
    ()=>
      !this.state.mas_filtros && this.props.onSelect(item)
    );
  }


  render (){
    const {onSelect} = this.props;

      return(
        <div className="row template-demo">
          <Spinner visible={this.state.show_loader}/>

          <button
            type="button"
            className="btn btn-outline-secondary"
            onClick={()=>this.showHideEscoger()}
          >
            { this.state.mas_filtros?
                <i className="mdi mdi-close"></i>
              : "Buscar por parroquia... "
            }
           </button>


          { this.state.mas_filtros?
            this.state.cantones.length ===0 &&
              <React.Fragment>
              <button type="button" className="btn btn-outline-secondary"
                onClick={()=>this.setState({mas_filtros:false})}>
                              <i className="mdi mdi-arrow-left btn-icon-prepend"></i> Volver </button>
              <span className="m-4" >Escoja la provincia </span>
              { this.state.provincias.map((item,index)=>
                <button type="button" className="btn btn-primary btn-rounded btn-fw"
                  onClick={()=>this.handleCantones(item.id)}
                > {item.nombre} </button>
                )
              }
              </React.Fragment>
            :null
          }

          { this.state.mas_filtros?
            this.state.parroquias.length ===0 && this.state.cantones.length !==0 ?
            <React.Fragment>
            <button type="button" className="btn btn-outline-secondary "
              onClick={()=>this.setState({cantones:[]})}>
                            <i className="mdi mdi-arrow-left btn-icon-prepend"></i> Volver </button>
            <span className="m-4" > Escoja el cantón </span>
              {this.state.cantones.map((item,index)=>
                  <button type="button" className="btn btn-info btn-rounded btn-fw"
                    onClick={()=>this.handleParroquias(item.id)}
                  > {item.nombre} </button>
                )
              }
              </React.Fragment>
              :null
            :null
          }
          { this.state.mas_filtros?
            this.state.parroquias.length!==0 &&
            <React.Fragment>
            <button type="button" className="btn btn-outline-secondary "
              onClick={()=>this.setState({parroquias:[], parroquia:0})}>
                            <i className="mdi mdi-arrow-left btn-icon-prepend"></i> Volver </button>
            <span className="m-4" >Seleccione la parroquia </span>
              {this.state.parroquias.map((item,index)=>
                  <button
                    type="button"
                    className={this.state.parroquia===item.id? "btn btn-success btn-rounded btn-fw": "btn btn-secondary btn-rounded btn-fw"}
                    onClick={()=>{
                        this.setState({
                            parroquia: item.id
                        },()=> onSelect(item))

                    }}
                  > {item.nombre} </button>
                )
              }
              </React.Fragment>
            :null
          }
        </div>
      )
  }

  handleProvincias = () => {
      //console.log(this.state);
      new WebApi().getAllProvincias().then(result => {
        //setTimeout(() => {
            this.processProvincias(result);
        //}, 300)
      })
  }

  processProvincias = (result) => {
      if (result.no_result) {
          return
      }
      console.log(result.data);
      let { data } = result;
      if (data.result) {
          this.setState({
            provincias: data.provincias
          });
      } else {
          alert(data.message);
      }
  }

  //buscar cantones
  handleCantones = (provincia) => {
    this.setState({show_loader:true});
      new WebApi().getCantonesPorProvincia(provincia).then(result => {
        this.processCantones(result);
      })
  }

  processCantones = (result) => {
      this.setState({show_loader:false});
      if (result.no_result) {
          return
      }
      let { data } = result;
      if (data.result) {
          this.setState({cantones: data.cantones},()=> this.state.canton>0 && this.handleParroquias());
      } else {
          alert(data.message);
      }
  }

  handleParroquias = (canton) => {
    this.setState({show_loader:true});
    new WebApi().getParroquiasPorCanton(canton).then(result => {
      this.processParroquias(result);
    })
  }

  processParroquias = (result) => {
    this.setState({show_loader:false});
    if (result.no_result) {
        return
    }
    let { data } = result;
    if (data.result) {
        this.setState({parroquias: data.parroquias});
    } else {
        alert(data.message);
    }
  }
}
