import React,{Component} from 'react';
import WebApi from '../components/helpers/WebApi';
import { connect } from 'react-redux';
import { setUserData } from '../components/redux/actions/ActionsUserData';
import MyStorage from '../components/helpers/MyStorage';
import '../assets/css/login.css';

class Login extends Component {

  state ={
      email : '',
      password: ''
  }
  handleChange = (e) => {
      this.setState({
          [e.target.id]: e.target.value
      })
  }
  handleSubmmit = (e) => {
      e.preventDefault();
      console.log(this.state);
      new WebApi().userLogin(this.state.email, this.state.password).then(result => {
        setTimeout(() => {
            this.processLoginResponse(result);
        }, 300)
      })
  }

  processLoginResponse = (result) => {

      console.log(result.data);
      let { data } = result;
      if (data.result) {
          //se redirecciona automaticamente
          let user = data.data;
          this.props.setUserData(user);
          user = JSON.stringify(data.data)
          new MyStorage().setUserInfo(user);
      } else {
          alert(data.message);
      }
  }

  form = () => {
    return (
      <form action="#!" onSubmit={this.handleSubmmit}>
        <div className="form-group">
          <label htmlFor="email">Email</label>
          <input type="email" name="email" id="email" className="form-control" placeholder="email@ejemplo.com"  onChange={this.handleChange}/>
        </div>
        <div className="form-group mb-4">
          <label htmlFor="password">Contraseña</label>
          <input type="password" name="password" id="password" className="form-control" placeholder="Escriba su contraseña"  onChange={this.handleChange}/>
        </div>
        <input name="login" id="login" className="btn btn-block login-btn" type="submit" value="Entrar"/>
      </form>
    )
  }

  rightSide = () => {
    return (
      <div className="col-sm-6 px-0 d-none d-sm-block">
        <img src="assets/images/login.jpg"  className="login-img" alt=" login"/>
      </div>
    )
  }

  render (){
    return (
      <main>
        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-6 login-section-wrapper">
              <div className="brand-wrapper">
                <img src="assets/images/logo.png" alt="logo" className="logo"/>
              </div>
              <div className="login-wrapper my-auto">
                <h1 className="login-title">Iniciar Sesión</h1>
                {this.form()}

              </div>
            </div>
            {this.rightSide()}
          </div>

        </div>
      </main>
    )
  }


}

const mapStateToProps = (state) => {
    return {
        user: state.user_data
    }
}

const actions = {
    setUserData
}


//export default Login;
export default connect(mapStateToProps, actions)(Login);

/*
<!--<a href="#!" className="forgot-password-link">Forgot password?</a> -->
<!--<p className="login-wrapper-footer-text">Don't have an account? <a href="#!" className="text-reset">Register here</a></p>-->
*/
