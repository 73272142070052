import React,{Component} from 'react';
import WebApi from '../../components/helpers/WebApi';
import {IVA} from '../../components/helpers/Constantes';
import {CustomInput, ComboBox} from '../../components/common/Parts';
import Spinner from '../../components/common/Spinner';
import { connect } from 'react-redux';
// ES6 Modules or TypeScript
import Swal from 'sweetalert2';
import NumberFormat from 'react-number-format';
// CommonJS
//const Swal = require('sweetalert2');
import { ReactSearchAutocomplete } from "react-search-autocomplete";
import moment from 'moment/min/moment-with-locales';
import Moment from 'react-moment';

//import 'moment-timezone';
Moment.globalMoment = moment;
Moment.globalLocale = 'es';
Moment.globalFormat = 'dddd DD-MMM-YYYY HH:mm';

class Preview extends Component {
  state={
    id:"",
    nombre:"",
    show_loader:false,
    tarifas:[],
    tarifa:0,
    nombre_tarifa:"",
    precio_tarifa: 0,
    detalle:[],
    subtotal:0,
  }

  ITEM=this.props.item || false;
  componentDidMount(){
    this.getFactura();
    this.handleGetFacturas();
  }


  render(){
    return(
      <div className="card">
        <div className="card-body">
          <div className="row">
            <Spinner visible={this.state.show_loader}/>
            <div className="col-sm-6">
                <div className="form-group">
                  <label forhtml="cliente">Cliente: </label>
                  <p>{this.ITEM.cliente}</p>
                </div>
                <div className="form-group">
                  <label forhtml="cliente">Cédula: </label>
                  <p>{this.ITEM.cedula}</p>
                </div>
                <div className="form-group">
                  <label forhtml="cliente">Fecha: </label>
                  <p><Moment locale="es">{this.ITEM.fecha}</Moment></p>
                </div>
            </div>
            <div className="col-sm-6">
                <div className="form-group">
                  <label forhtml="cliente">Email: </label>
                  <p>{this.ITEM.email}</p>
                </div>
                <div className="form-group">
                  <label forhtml="cliente">Dirección: </label>
                  <p>{this.ITEM.direccion}</p>
                </div>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-12 template-demo">
              <h4>Descripción</h4>
              <table className="table table-striped" style={{marginBottom: 40}}>
                  <thead>
                    <tr>
                      <th>Cant</th>
                      <th>Tarifa</th>
                      <th>Unit</th>
                      <th>Total</th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      this.state.detalle.map((item, index)=>
                      <tr key={index}>
                        <td className="py-1">{item.cantidad}</td>
                        <td>{item.descripcion}</td>
                        <td>$ {item.precio}</td>
                        <td>$ {item.precio*item.cantidad}</td>
                      </tr>
                      )
                    }
                  </tbody>
              </table>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-8">
            </div>
            <div class="col-xl-4 grid-margin">
              <div className="card stretch-card mb-3">
                <div className="card-body d-flex flex-wrap justify-content-between">
                  <div>
                    <h4 className="font-weight-semibold mb-1 text-black"> SubTotal </h4>
                  </div>
                  <h3 className="text-success font-weight-bold">
                    <NumberFormat decimalScale={2} fixedDecimalScale={true} prefix={'$'} displayType={'text'} value={this.getSubTotal()} />
                  </h3>
                </div>
                <div className="card-body d-flex flex-wrap justify-content-between">
                  <div>
                    <h4 className="font-weight-semibold mb-1 text-black"> IVA </h4>
                  </div>
                  <h3 className="text-success font-weight-bold">
                    <NumberFormat decimalScale={2} fixedDecimalScale={true} prefix={'$'} displayType={'text'} value={this.getSubTotal()*IVA/100} />
                  </h3>
                </div>
                <div className="card-body d-flex flex-wrap justify-content-between">
                  <div>
                    <h4 className="font-weight-semibold mb-1 text-black"> Total </h4>
                  </div>
                  <h3 className="text-success font-weight-bold">
                    <NumberFormat decimalScale={2} fixedDecimalScale={true} prefix={'$'} displayType={'text'} value={this.getSubTotal()+(this.getSubTotal()*IVA/100)} />
                  </h3>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  getSubTotal = () => {
    let total=0;
    this.state.detalle.forEach((item, i) => {
      total+=parseFloat(item.precio);
    });
    return total;
  }

  getFactura = () => {
    if(!this.ITEM){
      return;
    }
    this.setState({
      id: this.ITEM.id,
      nombre:this.ITEM.nombre,
      provincia: this.ITEM.provincia,
      canton: this.ITEM.canton,
      parroquia: this.ITEM.parroquia,
      direccion: this.ITEM.direccion,
      latitud: this.ITEM.latitud,
      longitud: this.ITEM.longitud,
    })
  }



  alertConfirm = ()=> {
    Swal.fire({
        title: 'Confirma',
        text: '¿Estás seguro que deseas eliminarlo?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Si',
        cancelButtonText: 'No, cancelar'
      }).then((result) => {
        if (result.value) {
          this.deleteCanton();
          /*
          Swal.fire(
            'Deleted!',
            'Your imaginary file has been deleted.',
            'success'
          )
        */
        // For more information about handling dismissals please visit
        // https://sweetalert2.github.io/#handling-dismissals
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          console.log("cancelar");
        }
      })
  }



  resetForm = () => {
    this.setState({
      id: 0,
      nombre: "",
      provincia: 0,
      mensaje: "",
    })
  }

  handleGetFacturas = () => {
      console.log(this.state);
      this.setState({show_loader:true, facturas: []});
      new WebApi().getFacturaDetalle(this.ITEM.id).then(result => {
        this.processGetFacturasResponse(result);
      })
  }

  processGetFacturasResponse = (result) => {
      this.setState({show_loader:false});
      if (result.no_result) {
          return
      }
      let { data } = result;
      //console.log("datas ", data);
      if (data.result) {
          this.setState({detalle: data.facturas});
      } else {
          alert(data.message);
      }
  }
}

const mapStateToProps = (state) => {
    return {
        user: state.user_data
    }
}
const actions = {};
export default connect(mapStateToProps, actions)(Preview);
