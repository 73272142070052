import React,{Component} from 'react';
import WebApi from '../../components/helpers/WebApi';
import {IVA} from '../../components/helpers/Constantes';
import { Footer, NewItem} from '../../components/common/Parts';
import RightNavigation from '../../components/common/RightNavigation';
import TopNavigation from '../../components/common/TopNavigation';
import Spinner from '../../components/common/Spinner';
import {CustomInput} from '../../components/common/Parts';
//import EscogerParroquia from '../../components/common/EscogerParroquia';
import Paginate from '../../components/common/Pagination';
import ArqueoDetalle from './ArqueoDetalle';
import '../../App.css';
//https://www.npmjs.com/package/react-moment
import moment from 'moment/min/moment-with-locales';
import Moment from 'react-moment';

//import 'moment-timezone';
Moment.globalMoment = moment;
Moment.globalLocale = 'es';
Moment.globalFormat = 'dddd DD-MMM-YYYY HH:mm';

Date.prototype.toDateInputValue = (function() {
    var local = new Date(this);
    local.setMinutes(this.getMinutes() - this.getTimezoneOffset());
    return local.toJSON().slice(0,10);
});

class Arqueo extends Component {
  PAGE_NUM=1;
  state ={
      search : '',
      cobros: [],
      total: 0,
      perPage: 0,
      item_selected: false,
      showCobroEdit:false,
      show_loader: false,
      parroquia:0,
      fecha: "",
  }

  componentDidMount(){
    this.cobrosDeHoy();
    //this.handleGetCobros();

  }

  handleChange = (e) => {
      this.setState({
          [e.target.id]: e.target.value
      })
  }

  handleSubmit = (e) => {
      e.preventDefault()
      this.handleGetCobros();
  }

  cobrosDeHoy = () => {
      this.setState({
          fecha: new Date().toDateInputValue()
        }, ()=>
          this.handleGetCobros(),
      );
  }

  handleGetCobros = () => {
      this.setState({
          show_loader:true,
          cobros: [],
      });
      new WebApi().getCobrosArqueo(this.state.fecha, this.PAGE_NUM).then(result => {
        this.processGetCobrosResponse(result);
      });
  }

  processGetCobrosResponse = (result) => {
      this.setState({show_loader:false});
      if (result.no_result) {
          return
      }
      let { data } = result;
      console.log("datas ", data);
      if (data.result) {
          this.setState({total: data.total, cobros: data.cobros, perPage: data.perPage});
      } else {
          alert(data.message);
      }
  }

  onSelectParroquia = (item) => {
    this.setState({parroquia: item.id},
      ()=>this.handleGetCobros()
    )
  }

  buscador= ()=> {

    return(
      <form className="d-flex align-items-center" onSubmit={this.handleSubmit}>
        <div className="col-sm-4">

        </div>
        <div className="col-sm-4">
        <CustomInput
          name={"fecha"}
          label={"Fecha del reporte"}
          onChangeText={this.handleChange}
          value={this.state.fecha}
          type="date"
          required
        />
        </div>
        <div className="col-sm-4">
          <button className="btn btn-sm btn-primary" type="submit"> Buscar </button>
        </div>
      </form>
    )
  }

  table = () => {
      return (
        <React.Fragment>
          <Spinner visible={this.state.show_loader}/>
            <div className="row">
            { this.state.cobros.map((item, index)=>
              <div className="col-sm-4 stretch-card grid-margin" key={item.usuario_Id} onClick={()=>this.setState({item_selected:item, showCobroEdit: true})}>
                <div className="card">
                  <div className="card-body px-3 text-dark">
                    <div className="d-flex justify-content-between">
                      <p className="text-muted font-13 mb-0">Cajero # {item.cajero}</p>
                      <i className="mdi mdi-coin"></i>
                    </div>
                    <h5 className="font-weight-semibold"> {item.nombre_cajero} </h5>
                    <div className="d-flex justify-content-between font-weight-semibold">
                      <p className="mb-0"></p>
                      <p className="mb-0">Total cobrado: ${this.truncate(item.total)}</p>
                    </div>
                  </div>
                </div>
              </div>
              )
            }
            </div>
        </React.Fragment>
      )
    }

  truncate = (valor) => {
    return parseFloat(valor).toFixed(2);
  }

  calculateIva = (valor) => {
    let iva_calc= 1+(IVA/100);
    return parseFloat(valor*iva_calc).toFixed(2);
  }

  hideModal = () => {
    this.setState({
      showCobroEdit:false,
    })
  }

  render (){
    let adjacents  = 4; //brecha entre páginas después de varios adyacentes
    //let offset = (this.PAGE_NUM - 1) * this.state.perPage;
    let total_pages = Math.ceil(this.state.total/this.state.perPage);

    return (
      <div className="container-scroller">
        {/*<!-- partial:partials/_sidebar.html -->*/}
        <RightNavigation/>
        {/*<!-- partial -->*/}
        <div className="container-fluid page-body-wrapper">
          {/*<!-- partial:partials/_settings-panel.html -->*/}
          {/*<!-- partial -->*/}
          {/*<!-- partial:partials/_navbar.html -->*/}
          <TopNavigation/>

          {/*<!-- partial -->*/}
          <div className="main-panel">
            <div className="content-wrapper pb-0">

              {/*Content here*/}
              {this.state.showCobroEdit &&
                <div className="modal-dialog modal-lg" role="dialog" aria-hidden="false">
                  <div className="modal-content">
                  <div className="modal-header">
                    <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={this.hideModal}>
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  <div className="modal-body" style={{backgroundColor:"#fff"}}>
                    <ArqueoDetalle item={this.state.item_selected}  hideModal={this.hideModal} handleGetCobros={this.handleGetCobros}/>
                  </div>
                </div>
              </div>
              }
              {!this.state.showCobroEdit && this.buscador()}
              {!this.state.showCobroEdit && this.table()}
              {!this.state.showCobroEdit &&
                <Paginate tpages={total_pages}  page={this.PAGE_NUM} adjacents={adjacents} load_next={this.load_next}/> }
              {this.state.cobros.length > 0 &&
                <div style={{ textAlign:"right",marginTop: 30 }}>Total de cobros: {this.state.total}</div>
              }
            </div>
            {/*<!-- content-wrapper ends -->*/}
            {/*<!-- partial:partials/_footer.html -->*/}
            <Footer/>
            {/*<!-- partial -->*/}
          </div>
          {/*<!-- main-panel ends -->*/}
        </div>
        {/*<!--page-body-wrapper ends-->*/}

      </div>
    )
  }

  refresh = () => {
    this.handleGetCobros();
  }

  load_next = (page) => {
    this.PAGE_NUM= page;
    this.handleGetCobros();
  }



}



export default Arqueo;
