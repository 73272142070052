import React from 'react'; //,{Component}

const Paginate = ({page, tpages, adjacents,load_next}) => {
  console.log("props: ", tpages);
	let prevlabel = "< Anterior"; //&lsaquo;
	let nextlabel = "Siguiente >"; //&rsaquo;
  return (
    <ul className="pagination " style={{marginTop: 20}}>
    	{/* previous label*/}
    	{ page===1  ?
    		<li className='page-item disabled'><span className="page-link">{prevlabel}</span></li>
        :
        page===2 ?
        <li className='page-item'><span  className="page-link" onClick={()=>load_next(1)}>{prevlabel}</span></li>
        :
        <li className='page-item'><span  className="page-link" onClick={()=>load_next(page-1)}>{prevlabel}</span></li>
    	}
    	{/* first label */}
    	{page>(adjacents+1) && <li className='page-item'><span  className="page-link" onClick={()=>load_next(1)}>1</span></li> }

    	{/* interval */}
    	{page>(adjacents+2) && <li className='page-item'><span className="page-link">...</span></li>}

    	{/* pages */}
    	{loop_pagination(page, adjacents, tpages, load_next)}

    	{/* interval */}
    	{page<(tpages-adjacents-1) && <li className='page-item'><span className="page-link">...</span></li>}

    	{/* last */}
    	{page<(tpages-adjacents) && <li className='page-item'><span className="page-link" onClick={()=>load_next(tpages)}>{tpages}</span></li> }
    	{/* next */}
    	{page<tpages ?
    		<li className='page-item'><span className="page-link" onClick={page+1}>{nextlabel}</span></li>
    	:
    		<li className='disabled page-item'><span className="page-link">{nextlabel}</span></li> }
      </ul>
    )

}

export default Paginate;


const loop_pagination = (page, adjacents, tpages, load_next) =>{
  let items=[];
  let pmin = (page>adjacents) ? (page-adjacents) : 1;
	let pmax = (page<(tpages-adjacents)) ? (page+adjacents) : tpages;
	for(var i=pmin; i<=pmax; i++) {
		if(i===page) {
			items.push(<li className='active page-item'><span className="page-link">{i}</span></li>) ;
		}else if(i===1) {
			items.push(<li className='page-item'><span className="page-link" onClick={()=>load_next(1)}>{i}</span></li>);
		}else {
			items.push(<li className='page-item'><span className="page-link" onClick={()=>load_next(i)}>{i}</span></li>);
		}
	}
  return (
    <React.Fragment>
      {items}
    </React.Fragment>
  )
}
