import React,{Component} from 'react';
import WebApi from '../../components/helpers/WebApi';
import {CustomInput, ComboBox} from '../../components/common/Parts';
import Spinner from '../../components/common/Spinner';
// ES6 Modules or TypeScript
import Swal from 'sweetalert2';
import { ReactSearchAutocomplete } from "react-search-autocomplete";
// CommonJS
//const Swal = require('sweetalert2');

import { connect } from 'react-redux';

class Nodo extends Component {
  state={
    id:"",
    nombre:"",
    longitud:"",
    latitud: "",
    descripcion: "",
    provincia:0,
    canton:0,
    parroquia:0,
    mensaje:"",
    provincias:[],
    cantones: [],
    parroquias: [],
    show_loader:false,
    nodo_anterior: null,
    nombre_nodo_anterior: "",
  }

  ITEM=this.props.item || false;

  componentDidMount(){
    this.getNodo();
    this.handleProvincias();
  }



  render(){
    return(
      <form action="#!" onSubmit={this.handleSubmmit}>
        {this.state.mensaje!=="" && <div className="alert alert-info">{this.state.mensaje}</div>}
        <div className="row">
          <Spinner visible={this.state.show_loader}/>
          <div className="col-sm-6">
            <CustomInput
              name={"nombre"}
              label={"Nombre"}
              onChangeText={this.handleChange}
              value={this.state.nombre}
              required
            />
            <CustomInput
              name={"direccion"}
              label={"Dirección"}
              onChangeText={this.handleChange}
              value={this.state.direccion}
              required
            />
            <CustomInput
              name={"longitud"}
              label={"Longitud"}
              onChangeText={this.handleChange}
              value={this.state.longitud}
              required
            />
            <CustomInput
              name={"latitud"}
              label={"Latitud"}
              onChangeText={this.handleChange}
              value={this.state.latitud}
              required
            />
          </div>
          <div className="col-sm-6">
            <ComboBox
              items={this.state.provincias}
              name={"provincia"}
              label={"Provincia"}
              onChangeText={this.handleChange}
              value={this.state.provincia}
              required
            />
            <ComboBox
              items={this.state.cantones}
              name={"canton"}
              label={"Cantón"}
              onChangeText={this.handleChange}
              value={this.state.canton}
              required
            />
            <ComboBox
              items={this.state.parroquias}
              name={"parroquia"}
              label={"Parroquia"}
              onChangeText={this.handleChange}
              value={this.state.parroquia}
              required
            />

            <div className="form-group">
              <label forhtml="cliente">Nodo de enlace (opcional): </label>
                <ReactSearchAutocomplete
                  items={this.state.nodos}
                  onSearch={this.handleOnSearchNodo}
                  onSelect={this.handleOnSelectNodo}
                  placeholder={this.state.nombre_nodo_anterior || "Nodo al que se va a enlazar"}
                  autoFocus
                />
            </div>

            <button type="submit" className="btn btn-primary mr-2"> Guardar </button>
            {this.props.hideModal && <button className="btn btn-light mr-2" onClick={this.props.hideModal} type="button" >Cancelar</button>}
            {this.ITEM &&
              <button className="btn btn-danger" type="button" onClick={this.alertConfirm} >Eliminar</button>}
            {!this.ITEM && <button className="btn btn-light mr-2" type="button" onClick={this.resetForm}>Limpiar </button>}
          </div>
        </div>
      </form>
    );
  }

  handleChange = (e) => {
    let name=e.target.name;
    this.setState({
        [name]: e.target.value
    }, ()=>this.subCargaDeDatos(name));
  }

  subCargaDeDatos = (name) => {
    if(name==="provincia"){
      this.handleCantones();
    }
    if(name==="canton"){
      this.handleParroquias();
    }
  }



  handleSubmmit = (e) => {
    e.preventDefault();
    let { nombre,direccion, latitud, longitud, parroquia,nodo_anterior } =this.state;
    if(nombre.trim() < 2 ) {
      alert("Escriba el nombre");
      return;
    }
    if(direccion.trim() < 1 ) {
      alert("Escoja la dirección");
      return;
    }
    if(latitud.trim() ==="") {
      alert("Escoja la latitud");
      return;
    }
    if(longitud.trim() ==="" ) {
      alert("Escoja la longitud");
      return;
    }
    if(parroquia.trim() < 1 ) {
      alert("Escoja la parroquia");
      return;
    }
    this.setState({show_loader:true});
      new WebApi().setNodo( this.ITEM.id, nombre, direccion, parroquia,latitud, longitud,nodo_anterior ).then(result => {
        setTimeout(() => {
            this.processsSetNodo(result);
        }, 300)
    })
  }

  processsSetNodo = (result) => {
    if (result.no_result) {
        return
    }
    this.setState({show_loader:false});
    let { data } = result;
    if (data.result) {
      this.setState({
        id: data.id,
        mensaje: data.message,
      });
      typeof this.props.handleGetNodos !== "undefined" && this.props.handleGetNodos()
      typeof this.props.hideModal !== "undefined" && this.props.hideModal()
    } else {
        alert(data.message);
    }
  }

  getNodo = () => {
    if(!this.ITEM){
      return;
    }
    this.setState({
      id: this.ITEM.id,
      nombre:this.ITEM.nombre,
      provincia: this.ITEM.provincia,
      canton: this.ITEM.canton,
      parroquia: this.ITEM.parroquia,
      direccion: this.ITEM.direccion,
      latitud: this.ITEM.latitud,
      longitud: this.ITEM.longitud,
      nodo_anterior: this.ITEM.nodo_anterior,
      nombre_nodo_anterior: this.ITEM.nombre_nodo_anterior,
    })
  }



  alertConfirm = ()=> {
    Swal.fire({
        title: 'Confirma',
        text: '¿Estás seguro que deseas eliminarlo?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Si',
        cancelButtonText: 'No, cancelar'
      }).then((result) => {
        if (result.value) {
          this.deleteCanton();
          /*
          Swal.fire(
            'Deleted!',
            'Your imaginary file has been deleted.',
            'success'
          )
        */
        // For more information about handling dismissals please visit
        // https://sweetalert2.github.io/#handling-dismissals
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          console.log("cancelar");
        }
      })
  }

  deleteCanton = () => {
    //return;
    if(!this.ITEM){
      return;
    }
    this.setState({show_loader:true});
    new WebApi().deleteCanton(this.ITEM.id).then(result => {
        this.processdeleteCanton(result);
    })
  }

  processdeleteCanton = (result) => {
    this.setState({show_loader:false});
    if (result.no_result) {
        return
    }
    let { data } = result;
    if (data.result) {
      typeof this.props.handleGetNodos !== "undefined" && this.props.handleGetNodos()
      typeof this.props.hideModal !== "undefined" && this.props.hideModal()
    }
  }

  resetForm = () => {
    this.setState({
      id: 0,
      nombre: "",
      provincia: 0,
      canton: 0,
      parroquia: 0,
      direccion: 0,
      latitud: "",
      longitud: "",
      nodo_anterior: null,
      nombre_nodo_anterior: "",
      mensaje: "",
    })
  }

  handleProvincias = () => {
      //console.log(this.state);
      new WebApi().getAllProvincias().then(result => {
        //setTimeout(() => {
            this.processProvincias(result);
        //}, 300)
      })
  }

  processProvincias = (result) => {
      if (result.no_result) {
          return
      }
      console.log(result.data);
      let { data } = result;
      if (data.result) {
          this.setState({
            provincias: data.provincias
          },()=> this.state.parroquia>0 && this.handleCantones());
      } else {
          alert(data.message);
      }
  }

  //buscar cantones
  handleCantones = () => {
    this.setState({show_loader:true});
      new WebApi().getCantonesPorProvincia(this.state.provincia).then(result => {
        this.processCantones(result);
      })
  }

  processCantones = (result) => {
      this.setState({show_loader:false});
      if (result.no_result) {
          return
      }
      let { data } = result;
      if (data.result) {
          this.setState({cantones: data.cantones},()=> this.state.canton>0 && this.handleParroquias());
      } else {
          alert(data.message);
      }
  }

  handleParroquias = () => {
    this.setState({show_loader:true});
    new WebApi().getParroquiasPorCanton(this.state.canton).then(result => {
      this.processParroquias(result);
    })
  }

  processParroquias = (result) => {
    this.setState({show_loader:false});
    if (result.no_result) {
        return
    }
    let { data } = result;
    if (data.result) {
        this.setState({parroquias: data.parroquias});
    } else {
        alert(data.message);
    }
  }

  handleOnSearchNodo = (string, cached) => {
    console.log(string, cached);
    this.setState({show_loader:true});
      new WebApi().getSearchNodos( string , 1).then(result => {
        this.processSearchNodos(result);
    });
  }

  processSearchNodos = (result) => {
    this.setState({show_loader:false});
    if (result.no_result) {
        return
    }
    let { data } = result;
    if (data.result) {
      this.setState({nodos: data.nodos})
    }
    //console.log(data.clientes);
  }

  handleOnSelectNodo = item => {
    // the item selected
    this.setState({
      nodo_anterior:item.id
    });
    //console.log(item);
  }

}

const mapStateToProps = (state) => {
    return {
        user: state.user_data
    }
}
const actions = {};
export default connect(mapStateToProps, actions)(Nodo);
